@use 'sass:math';

@import 'styles/scoped';

.benefitsModule {
    @include spacing('padding-top', 's-160');
    @include spacing('padding-bottom', 's-160');

    @include mq(sm-md) {
        @include spacing('padding-top', 's-80');
        @include spacing('padding-bottom', 's-80');
    }
}

.header {
    @include spacing('margin-bottom', 's-80');

    @include mq(sm-md) {
        @include spacing('margin-bottom', 's-64');
    }
}

.eyebrow {
    @include spacing('margin-bottom', 's-32');

    color: $red;
}

.title {
    max-width: rem(960px);
    margin-left: auto;
    margin-right: auto;

    @include mq(null, xl) {
        max-width: get-vw(960px);
    }
}

.content {
    display: flex;
    flex-wrap: wrap;

    @include mq(sm-md) {
        flex-direction: column;
        align-items: center;
    }
}

.benefit {
    width: percentage(1, 3);
    padding: 0 percentage(1, 18);

    @include mq(lg) {
        padding: 0 percentage(0.5, 18);
    }

    @include mq(sm-md) {
        @include spacing('margin-bottom', 's-64');

        width: 100%;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.benefitInner {
    height: 100%;
    text-align: center;
}

.benefitImage {
    @include spacing('margin-bottom', 's-32');

    width: get-vw(80px);
    height: get-vw(80px);
    min-width: rem(80px);
    min-height: rem(80px);
    margin-left: auto;
    margin-right: auto;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    @include mq(sm-md) {
        width: get-vw(60px);
        height: get-vw(60px);
        min-width: rem(60px);
        min-height: rem(60px);
    }
}
