@import 'styles/scoped';

.storybookWrapper {
    width: 100%;
    display: flex;
    padding: 50px;
    background-color: $red;
    justify-content: center;
}

.wrapper {
    display: block;
}

.wrapperHas2 {
    .button:first-child {
        border-radius: 60px 0 0 60px;

        @include mq(null, xl) {
            border-radius: get-vw(60px) 0 0 get-vw(60px);
        }
    }

    .button:last-child {
        border-radius: 0 60px 60px 0;

        @include mq(null, xl) {
            border-radius: 0 get-vw(60px) get-vw(60px) 0;
        }
    }
}

.button {
    border-radius: 60px;

    @include mq(null, xl) {
        border-radius: get-vw(60px);
    }
}
