@import 'styles/scoped';

.list {
    display: block;
    @include spacing('padding-top', 's-64');
    @include spacing('padding-bottom', 's-64');

    li {
        @include spacing('padding-bottom', 's-16');

        &:last-child {
            padding-bottom: 0;
        }
    }
}
