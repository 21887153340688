@import 'styles/scoped';

.spinner {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    border: 0.2em solid $white;
    border-radius: 100%;
    border-top-color: $black;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
