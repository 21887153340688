@import 'styles/scoped';

.container {
    width: 100%;
    display: block;
    max-width: rem(600px);

    @include mq(null, xl) {
        max-width: get-vw(600px);
    }

    @include mq(md) {
        max-width: 100%;
    }
}

.btn {
    display: block;
    @include spacing('margin-top', 's-32');
}
