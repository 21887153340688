@use 'sass:math';

@import 'styles/scoped';

.verticalImageFeaturedParallaxModule {
    @include spacing('padding-top', 's-80');
    @include spacing('padding-bottom', 's-80');

    display: flex;
    align-items: center;

    @include mq(sm-md) {
        flex-direction: column;
    }
}

.content {
    width: percentage(11, 18);
    padding-right: percentage(1, 18);
    display: flex;

    @include mq(sm-md) {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
        order: 2;
    }

    .contentRight & {
        order: 2;
        padding-right: 0;
        justify-content: flex-end;

        @include mq(lg) {
            padding-left: percentage(1, 18);
        }

        @include mq(sm-md) {
            padding-left: 0;
            justify-content: flex-start;
        }
    }
}

.contentInner {
    max-width: rem(420px);

    @include mq(null, xl) {
        max-width: get-vw(420px);
    }

    @include mq(sm-md) {
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }
}

.eyebrow {
    @include spacing('margin-bottom', 's-32');

    color: $red;
}

.title {
    @include spacing('margin-bottom', 's-16');

    max-width: rem(420px);

    @include mq(null, xl) {
        max-width: get-vw(420px);
    }

    @include mq(sm-md) {
        margin-left: auto;
        margin-right: auto;
    }
}

.description {
    @include spacing('margin-bottom', 's-32');

    max-width: rem(360px);

    @include mq(null, xl) {
        max-width: get-vw(360px);
    }

    @include mq(sm-md) {
        margin-left: auto;
        margin-right: auto;
    }

    :global {
        ul {
            @include mq(sm-md) {
                padding-left: 0;
                list-style-type: none;
            }

            li:before {
                display: none;
                content: '\2022';
                vertical-align: middle;
                @include spacing('margin-right', 's-8');

                @include mq(sm-md) {
                    display: inline-block;
                }
            }
        }
        ol {
            @include mq(sm-md) {
                padding-left: 0;
                list-style-type: none;
                counter-reset: numbers;
            }

            li {
                @include mq(sm-md) {
                    counter-increment: numbers;
                }
            }

            li:before {
                display: none;
                @include spacing('margin-right', 's-8');
                content: counter(numbers) '. ';

                @include mq(sm-md) {
                    display: inline-block;
                }
            }
        }
    }
}

.images {
    width: percentage(7, 18);
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    @include mq(sm-md) {
        @include spacing('margin-bottom', 's-56');

        width: 100%;
        padding-left: 0;
        padding-right: 0;
        order: 1;
        flex-direction: column;
    }

    .contentRight & {
        order: 1;
    }
}

.logos {
    @include spacing('margin-bottom', 's-40');

    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
    max-width: rem(420px);

    @include mq(null, xl) {
        max-width: get-vw(420px);
    }

    @include mq(sm-md) {
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
    }

    li {
        @include spacing('margin-right', 's-24');

        width: 33.333%;
    }

    img {
        width: 100%;
    }
}
