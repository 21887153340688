@import 'styles/scoped';

.header {
    width: 100%;
    display: block;
    background-color: $beige;
}

.headerTop {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;
    padding-left: percentage(3, 24);
    padding-right: percentage(3, 24);

    @include spacing('padding-top', 's-200');
    @include spacing('padding-bottom', 's-120');

    @include mq(lg) {
        padding-left: percentage(2, 24);
        padding-right: percentage(2, 24);
    }

    @include mq(md) {
        display: block;

        @include spacing('padding-top', 's-160');
        @include spacing('padding-bottom', 's-40');
    }
}

.headerTopLeft {
    flex: 0 0 percentage(4, 18);
    padding-right: percentage(1, 18);

    @include spacing('padding-top', 's-4');

    @include mq(md) {
        width: 100%;
        display: block;
        padding-right: 0;

        @include spacing('padding-bottom', 's-16');
    }
}

.headerTopRight {
    flex: 1 1 auto;
}

.title {
    max-width: rem(720px);

    @include mq(null, xl) {
        max-width: get-vw(720px);
    }
}

.category {
    display: block;
    color: $red;
    text-transform: uppercase;

    @include spacing('padding-bottom', 's-4');
}

.date {
    display: block;
}

.figure {
    width: 100%;
    display: block;
    position: relative;
    overflow: hidden;
    background-color: $white;
    padding-left: percentage(1, 24);
    padding-right: percentage(1, 24);

    img {
        width: 100%;
        display: block;
        border-radius: 10px;
        position: relative;
        z-index: 2;
        transform: rotate(0deg) translateZ(2px);

        @include mq(null, xl) {
            border-radius: get-vw(10px);
        }
    }
}

.figureShapeTop {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg) translateZ(1px);

    svg {
        position: relative;
        display: block;
        width: calc(455% + 1.3px);
        height: 60vw;
        transform: rotateY(180deg);
    }

    path {
        fill: $beige;
    }
}

.section {
    width: 100%;
    display: block;
    overflow: hidden;
    padding-left: percentage(3, 24);
    padding-right: percentage(3, 24);

    @include spacing('padding-bottom', 's-120');

    @include mq(lg) {
        padding-left: percentage(2, 24);
        padding-right: percentage(2, 24);
    }

    @include mq(md) {
        @include spacing('padding-bottom', 's-80');
    }
}

.sectionInner {
    width: 100%;
    display: block;
    position: relative;

    @include spacing('padding-top', 's-120');

    @include mq(md) {
        @include spacing('padding-top', 's-80');
    }
}

.article {
    display: block;
    position: relative;
    z-index: 1;
}

.articleBlock {
    width: 100%;
    display: block;
    padding-left: percentage(4, 18);
    padding-right: percentage(4, 18);

    @include spacing('margin-bottom', 's-64');

    @include mq(lg) {
        padding-left: percentage(3, 24);
        padding-right: percentage(2, 24);
    }

    @include mq(md) {
        padding-left: 0;
        padding-right: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.articleBlockFull {
    padding-left: 0;
    padding-right: 0;
}

.share {
    width: rem(60px);
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: block;
    position: absolute;
    text-align: center;

    @include mq(md) {
        display: none;
    }

    @include mq(null, xl) {
        width: get-vw(60px);
    }
}

.shareInner {
    width: 100%;
    display: block;

    @include spacing('padding-top', 's-120');

    @include mq(md) {
        @include spacing('padding-top', 's-64');
    }
}
