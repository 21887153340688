@use 'sass:math';

@import 'styles/scoped';

.featuredArticlesModule {
    @include spacing('padding-top', 's-160');
    @include spacing('padding-bottom', 's-160');

    @include mq(md) {
        @include spacing('padding-top', 's-120');
        @include spacing('padding-bottom', 's-120');
    }

    @include mq(sm-md) {
        @include spacing('padding-top', 's-80');
        @include spacing('padding-bottom', 's-80');
    }

    width: 100%;
    display: block;
    position: relative;
}

.top {
    width: 100%;
    display: block;
    margin: 0 auto;
    max-width: rem(840px);
    text-align: center;

    @include mq(null, xl) {
        max-width: get-vw(840px);
    }

    @include mq(md) {
        padding: 0 percentage(1, 24);
    }
}

.eyebrow {
    @include spacing('margin-bottom', 's-24');

    display: block;
    color: $red;
    font-weight: normal;
    text-transform: uppercase;
}

.title {
    @include spacing('margin-bottom', 's-24');

    max-width: rem(840px);
    display: block;

    @include mq(null, xl) {
        max-width: get-vw(840px);
    }
}

.items {
    @include spacing('margin-top', 's-80');

    width: 100%;
    display: block;

    @include mq(lg) {
        padding: 0;
    }
}

.itemrow {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: rem(-20px);
    margin-right: rem(-20px);

    @include mq(null, xl) {
        margin-left: get-vw(-20px);
        margin-right: get-vw(-20px);
    }

    @include mq(md) {
        margin: 0;
    }
}

.itemcol {
    display: block;
    width: 50%;
    padding-left: rem(20px);
    padding-right: rem(20px);

    @include mq(null, xl) {
        padding-left: get-vw(20px);
        padding-right: get-vw(20px);
    }

    @include mq(md) {
        @include spacing('margin-bottom', 's-64');

        width: 100%;
        padding: 0;
    }

    &:last-child {
        @include mq(md) {
            margin-bottom: 0;
        }
    }
}
