@use 'sass:math';

@import 'styles/scoped';

.videoComparisonModule {
    @include spacing('padding-top', 's-160');
    @include spacing('padding-bottom', 's-120');

    @include mq(sm-md) {
        @include spacing('padding-top', 's-80');
        @include spacing('padding-bottom', 's-80');
    }

    color: $white;
    padding-left: percentage(2, 24);
    padding-right: percentage(2, 24);

    @include mq(md) {
        padding-left: 0;
        padding-right: 0;
    }
}

.logo {
    @include spacing('margin-bottom', 's-40');

    max-width: rem(120px);
    margin-left: auto;
    margin-right: auto;
    width: 100%;

    @include mq(null, xl) {
        max-width: get-vw(120px);
    }
}

.eyebrow {
    @include spacing('margin-bottom', 's-40');
}

.title {
    @include spacing('padding-bottom', 's-80');

    max-width: rem(960px);
    margin: 0 auto;

    @include mq(null, xl) {
        max-width: get-vw(960px);
    }
}

.row {
    display: flex;
    flex-direction: row;

    @include mq(lg) {
        flex-wrap: wrap;
    }
}

.colTitle {
    @include spacing('padding-bottom', 's-8');
}

.colLeft {
    @include spacing('padding-bottom', 's-80');

    width: percentage(4, 20);
    margin-top: auto;
    position: relative;

    @include mq(lg) {
        @include spacing('margin-top', 's-80');

        width: 50%;
        padding-bottom: 0;
        padding-right: percentage(1, 20);
        padding-top: 0;
    }
}

.colRight {
    @include spacing('padding-top', 's-80');

    width: percentage(4, 20);
    position: relative;
    margin-bottom: auto;

    @include mq(lg) {
        @include spacing('margin-top', 's-80');

        width: 50%;
        padding-bottom: 0;
        padding-left: percentage(1, 20);
        padding-top: 0;
    }
}

.colMain {
    width: percentage(12, 20);
    padding-left: percentage(1, 20);
    padding-right: percentage(1, 20);

    @include mq(lg) {
        width: 100%;
        order: -1;
        padding: 0;
    }
}

.videoWrapper {
    border-radius: 20px;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);

    @include mq(null, xl) {
        border-radius: get-vw(20px);
    }

    video {
        width: 100%;
    }
}

.arrowLeft {
    display: block;
    position: absolute;
    bottom: 100%;
    left: percentage(3.5, 4);
    width: percentage(2.5, 4);
    height: auto;
    z-index: getZindex('shapes');

    @include mq(lg) {
        width: percentage(6, 10);
        // magic numbers rotate and fix rotation position with translate
        transform: rotate(-50deg) translateY(-50%);
        left: 0;
        bottom: 100%;
    }
}

.arrowRight {
    display: block;
    position: absolute;
    top: 100%;
    right: percentage(3.5, 4);
    width: percentage(2.5, 4);
    height: auto;
    z-index: getZindex('shapes');

    @include mq(lg) {
        width: percentage(6, 10);
        // magic numbers rotate and flip
        transform: rotate(50deg) scaleY(-1);
        right: -10%;
        bottom: 115%;
        top: unset;
    }
}

.playBtnWrapper {
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    position: absolute;
    pointer-events: none;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;
    transition: getTransition();
    opacity: 0;
    visibility: hidden;

    &--beige button {
        background-color: $beige;
    }

    &--white button {
        background-color: $white;
    }

    &--smoke button {
        background-color: $smoke;
    }

    button {
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        align-content: center;
        align-items: center;
        justify-content: center;
        -webkit-appearance: none;
        border-radius: 100%;
        border: none;
        cursor: pointer;
        pointer-events: all;
        transition: getTransition();
        transform-origin: center;
        transform: scale(1.5);

        @include spacing('width', 's-64');
        @include spacing('height', 's-64');

        svg {
            fill: $black;
            @include spacing('font-size', 's-24');
        }
    }
}

.playBtnWrapperActive {
    opacity: 1;
    visibility: visible;

    button {
        transform: scale(1);
    }
}
