@use 'sass:math';

@import 'styles/scoped';

.verticalImageFeaturedSlider {
    @include spacing('padding-top', 's-120');
    @include spacing('padding-bottom', 's-120');

    display: flex;
    align-items: center;

    @include mq(sm-md) {
        @include spacing('padding-top', 's-80');
        @include spacing('padding-bottom', 's-80');

        flex-direction: column;
    }
}

.contentSlider {
    width: percentage(11, 18);
    padding-right: percentage(1, 18);

    @include mq(sm-md) {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
        order: 2;
    }

    .contentRight & {
        order: 2;
        padding-right: 0;
        padding-left: percentage(4, 18);

        @include mq(lg) {
            padding-left: percentage(3, 18);
        }

        @include mq(md) {
            padding-left: percentage(1, 18);
        }

        @include mq(sm-md) {
            padding-left: 0;
        }
    }

    :global {
        .swiper-wrapper {
            align-items: center;

            @include mq(sm-md) {
                align-items: flex-start;
            }
        }
    }
}

.contentSliderSlide {
    transition: getCustomTransition(opacity);
    cursor: grab;

    &:global {
        &.swiper-slide-next,
        &.swiper-slide-prev {
            opacity: 0;
        }

        &.swiper-slide-active {
            opacity: 1;
        }
    }
}

.contentSliderHeader {
    @include spacing('margin-bottom', 's-32');

    display: flex;
    max-width: rem(360px);
    justify-content: space-between;
    align-items: center;

    @include mq(null, xl) {
        max-width: get-vw(360px);
    }

    @include mq(sm-md) {
        max-width: 100%;
    }
}

.contentSliderEyebrow {
    color: $red;
}

.contentSliderFraction {
    color: $gray;
}

.contentSliderTitle {
    @include spacing('margin-bottom', 's-16');

    max-width: rem(420px);

    @include mq(null, xl) {
        max-width: get-vw(420px);
    }
}

.contentSliderDescription {
    max-width: rem(420px);

    @include mq(null, xl) {
        max-width: get-vw(420px);
    }
}

.contentSliderLink {
    @include spacing('margin-top', 's-32');

    max-width: rem(420px);

    @include mq(null, xl) {
        max-width: get-vw(420px);
    }
}

.imageSlider {
    width: percentage(7, 18);

    @include mq(sm-md) {
        @include spacing('margin-bottom', 's-56');

        width: 100%;
        order: 1;
    }

    .contentRight & {
        order: 1;
    }
}

.imageSliderSlide {
    opacity: 0;
    transition: getCustomTransition(opacity);
    cursor: grab;

    &:global {
        &.swiper-slide-active {
            opacity: 1;
        }
    }

    &Desktop {
        display: block;

        @include mq(sm-md) {
            display: none;
        }
    }

    &Mobile {
        display: none;

        @include mq(sm-md) {
            display: block;
        }
    }
}

.arrows {
    @include spacing('padding-top', 's-80');

    @include mq(sm-md) {
        @include spacing('padding-top', 's-56');
    }
}

.arrow {
    &:first-child {
        @include spacing('margin-right', 's-16');
    }
}
