@use 'sass:math';

@import 'styles/scoped';

.clientListLogo {
    @include spacing('padding-top', 's-120');
    @include spacing('padding-bottom', 's-120');

    @include mq(md) {
        @include spacing('padding-top', 's-80');
        @include spacing('padding-bottom', 's-80');
    }
}

.header {
    @include spacing('margin-bottom', 's-80');
}

.title {
    max-width: rem(720px);
    margin-left: auto;
    margin-right: auto;

    @include mq(null, xl) {
        max-width: get-vw(720px);
    }
}

.content {
    @include spacing('margin-bottom', 's-80');

    :global {
        .swiper-container {
            height: rem(272px);
            overflow: visible;
            cursor: grab;

            @include mq(null, xl) {
                height: get-vw(272px);
            }
        }

        .swiper-slide {
            height: calc(#{rem(272px - 32px)} / 2);
            border: 1px solid $maize;
            box-sizing: border-box;
            border-radius: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $white;

            @include mq(null, xl) {
                height: calc(#{get-vw(272px - 32px)} / 2);
                border-radius: get-vw(6px);
                border: get-vw(1px) solid $maize;
            }
        }
    }
}

.client {
    width: 100%;
    height: 0;
    padding-bottom: percentage(37, 160);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    max-width: rem(160px);

    @include mq(null, xl) {
        max-width: get-vw(160px);
    }

    @include mq(lg) {
        padding-bottom: percentage(56, 360);
    }
}

.scrollbar {
    width: 100%;
    display: block;
}

.scrollbarItem {
    width: 100%;
    height: 3px;
    display: block;
    position: relative;
    background-color: $white;
    overflow: hidden;
    text-indent: -9999px;
    z-index: getZindex(shapes);

    @include mq(null, xl) {
        height: get-vw(3px);
    }

    & > div {
        display: block;
        top: 0;
        left: 0;
        height: 100%;
        border-radius: 40px;
        position: absolute;
        background-color: $red;
        cursor: move;

        @include mq(null, xl) {
            border-radius: get-vw(40px);
        }
    }
}

.scrollbarItemInverted {
    background-color: $beige;
}
