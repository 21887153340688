@import 'styles/scoped';

.articles {
    display: block;

    @include spacing('padding-top', 's-32');
}

.isLoading {
    opacity: 0.5;
    pointer-events: none !important;

    & * {
        pointer-events: none !important;
    }
}

.articlesBig {
    display: block;
    margin-left: -18px;
    margin-right: -18px;

    @include mq(md) {
        margin-left: 0;
        margin-right: 0;
    }
}

.articlesRow {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: flex-start;
}

.articlesBigCol {
    width: 50%;
    padding-left: 18px;
    padding-right: 18px;

    @include spacing('padding-bottom', 's-64');

    @include mq(null, xl) {
        padding-left: get-vw(18px);
        padding-right: get-vw(18px);
    }

    @include mq(md) {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
    }
}

.articlesSmall {
    display: block;
    margin-left: -12px;
    margin-right: -12px;

    @include mq(null, xl) {
        padding-left: get-vw(-12px);
        padding-right: get-vw(-12px);
    }

    @include mq(md) {
        margin-top: 0;
        margin-left: 0;
        margin-right: 0;
    }
}

.articlesSmallCol {
    width: 33.33%;
    padding-left: 12px;
    padding-right: 12px;

    @include spacing('padding-bottom', 's-64');

    @include mq(null, xl) {
        padding-left: get-vw(12px);
        padding-right: get-vw(12px);
    }

    @include mq(md) {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
    }
}

.emptyTitle {
    @include spacing('margin-top', 's-40');
    @include spacing('margin-bottom', 's-40');
}
