@import 'styles/scoped';

.section {
    @include spacing('padding-top', 's-80');
    @include spacing('padding-bottom', 's-80');

    width: 100%;
    display: block;
    background-color: $white;

    &white {
        background-color: $white;
    }

    &beige {
        background-color: $beige;
    }

    @include mq(sm-md) {
        overflow: hidden;
    }

    :global {
        .swiper-container {
            overflow: visible;
        }
    }
}

.swiperContainer {
    width: 100%;
    display: block;
}

.item {
    @include spacing('padding-bottom', 's-64');
    @include spacing('padding-right', 's-32');

    display: block;
    max-width: rem(285px);
    cursor: grab;

    @include mq(null, xl) {
        max-width: get-vw(285px);
    }
}

.pic {
    @include spacing('margin-bottom', 's-40');
    @include spacing('margin-bottom', 's-32');
    @include spacing('width', 's-120');
    @include spacing('height', 's-120');

    display: block;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left center;
}

.picFull {
    height: 0;
    width: 100%;
    padding-bottom: 100%;
}

.eyebrow {
    @include spacing('margin-bottom', 's-16');

    color: $red;
    display: block;
    text-transform: uppercase;
}

.title {
    @include spacing('margin-bottom', 's-8');

    display: block;
}

.scrollbar {
    width: 100%;
    display: block;
}

.scrollbarItem {
    width: 100%;
    height: 3px;
    display: block;
    position: relative;
    background-color: $white;
    overflow: hidden;
    text-indent: -9999px;
    z-index: getZindex(shapes);

    @include mq(null, xl) {
        height: get-vw(3px);
    }

    & > div {
        display: block;
        top: 0;
        left: 0;
        height: 100%;
        border-radius: 40px;
        position: absolute;
        background-color: $red;
        cursor: move;

        @include mq(null, xl) {
            border-radius: get-vw(40px);
        }
    }
}

.scrollbarItemInverted {
    background-color: $beige;
}
