@use 'sass:math';

@import 'styles/scoped';

.moduleWrapper {
    width: 100%;
    height: 100vh;
    overflow: hidden;

    @include mq(md) {
        height: auto;
    }

    &:global {
        &.has-cookie {
            :local {
                .headerWrapper {
                    transform: none;
                }

                .introWrapper {
                    display: none;
                }

                .eyebrow,
                .title,
                .cta,
                .parallaxWrapper {
                    transform: none;
                    opacity: 1;
                }
            }
        }
    }
}

.headerWrapper {
    position: relative;
    transform: translateY(200%);
    will-change: transform;
    backface-visibility: hidden;
}

.introWrapper {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    transform: translateY(100%);
    will-change: transform;
    backface-visibility: hidden;
}

.introBackground {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 120%;
    display: block;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transform: translateY(-20%);
    will-change: transform;
    backface-visibility: hidden;

    &--desktop {
        @include mq(md) {
            display: none;
        }
    }

    &--mobile {
        display: none;

        @include mq(md) {
            display: block;
        }
    }
}

.main {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
    color: $white;
    text-align: center;
    padding-left: percentage(1, 24);
    padding-right: percentage(1, 24);

    @include mq(md) {
        min-height: 1px;
        padding-top: rem(140px);
        padding-bottom: rem(200px);
    }
}

.container {
    display: block;
    width: 100%;
    max-width: rem(960px);
    margin: 0 auto;
    position: relative;
    z-index: getZindex(default, 2);

    @include mq(null, xl) {
        max-width: get-vw(960px);
    }
}

.eyebrow {
    @include spacing('padding-bottom', 's-40');

    position: relative;
    transform: translateY(170px);
    opacity: 0;
}

.logo {
    display: block;
    margin: 0 auto;
    @include spacing('width', 's-120');
    @include spacing('padding-bottom', 's-40');
    @include spacing('padding-left', 's-16');
    @include spacing('padding-right', 's-16');

    position: relative;
    transform: translateY(170px);
    opacity: 0;

    img {
        width: 100%;
        display: block;
    }
}

.title {
    @include spacing('margin-bottom', 's-56');

    position: relative;
    transform: translateY(170px);
    opacity: 0;
}

.cta {
    position: relative;
    transform: translateY(170px);
    opacity: 0;
}

.parallaxWrapper {
    opacity: 0;
}

.parallaxElement {
    width: rem(420px);
    height: rem(420px);
    position: absolute;
    z-index: getZindex(default, 1) !important;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    @include mq(null, xl) {
        width: get-vw(420px);
        height: get-vw(420px);
    }

    @include mq(md) {
        width: rem(210px);
        height: rem(210px);
    }

    img {
        width: 100%;
        display: block;
        position: relative;
        z-index: 2;
    }
}

.parallaxElementBack {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: getZindex(default) !important;
    top: 0;
    left: 0;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.parallaxTopLeft {
    top: get-vw(-30px);
    left: get-vw(-90px);

    @include mq(md) {
        top: rem(-30px);
        left: rem(-60px);
    }
}

.parallaxTopRight {
    top: get-vw(-30px);
    right: get-vw(-60px);

    @include mq(md) {
        top: rem(-30px);
        right: rem(-60px);
    }
}

.parallaxBottomLeft {
    left: get-vw(-90px);
    bottom: get-vw(-130px);

    @include mq(md) {
        left: rem(-60px);
        bottom: rem(-80px);
    }
}

.parallaxBottomRight {
    right: get-vw(-60px);
    bottom: get-vw(-60px);

    @include mq(md) {
        right: rem(-60px);
    }
}

.isBeige {
    color: $black;

    .eyebrow {
        color: $red;
    }
}
