@use 'sass:math';

@import 'styles/scoped';

.parallaxOvalImageGroup {
    position: relative;
    width: 100%;
    z-index: getZindex(parallax);
}

.shape {
    height: 0;
    border-radius: 320px;
    width: 100%;
    padding-bottom: percentage(740, 420);
    position: relative;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);

    @include mq(null, xl) {
        border-radius: get-vw(320px);
    }

    @include mq(sm-md) {
        padding-bottom: percentage(186, 328);
    }
}

.parallaxBackground {
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    height: 110%;
    top: -10%;
    left: 0;

    &Desktop {
        display: block;

        @include mq(sm-md) {
            display: none;
        }
    }

    &Mobile {
        display: none;

        @include mq(sm-md) {
            display: block;
        }
    }
}

.parallaxInnerImage {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    height: 84%;
    bottom: 0;
    left: 0;
    z-index: getZindex(parallax);

    @include mq(sm-md) {
        height: 150%;
        bottom: -30%;
    }
}

.parallaxImageTopLeft,
.parallaxImageBottomRight {
    width: get-vw(178px);
    height: get-vw(176px);
    display: block;
    position: absolute;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    z-index: getZindex(default);

    @include mq(md) {
        display: none;
    }
}

.parallaxImageTopLeft {
    top: get-vw(-35px);
    left: get-vw(-16px);
    margin-top: 25%;
    transform: rotate(-10deg);
}

.parallaxImageBottomRight {
    bottom: get-vw(90px);
    right: get-vw(-80px);
    margin-top: -25%;
    transform: rotate(10deg);
}
