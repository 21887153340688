@use 'sass:math';

@import 'styles/scoped';

.main {
    width: 100%;
    display: block;
    position: relative;
}

.mediaWrapper {
    height: 0;
    padding-bottom: percentage(700, 1440);
    overflow: hidden;
    position: relative;
    z-index: getZindex(default);
}

.media {
    position: absolute;
    width: 100%;
    height: 110%;
    top: -10%;
    left: 0;

    img,
    video {
        width: 100%;
        height: auto;
        object-fit: cover;
    }
}

.audioBtn {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    position: absolute;
    z-index: 5;
    border-radius: 100%;
    border: none;
    cursor: pointer;
    -webkit-appearance: none;
    background-color: $white;

    &--beige {
        background-color: $beige;
    }

    &--white {
        background-color: $white;
    }

    &--smoke {
        background-color: $smoke;
    }

    &--disabled svg {
        path {
            fill: $maize;
        }

        path:nth-child(2),
        path:nth-child(3) {
            opacity: 0;
            visibility: hidden;
        }
    }

    @include spacing('width', 's-64');
    @include spacing('height', 's-64');
    @include spacing('bottom', 's-40');
    @include spacing('right', 's-40');

    svg {
        fill: $black;
        @include spacing('font-size', 's-24');

        path {
            opacity: 1;
            visibility: visible;
            transition: getTransition();
        }
    }

    &:hover svg {
        fill: rgba($black, 0.5);
    }
}

.noParallax {
    height: auto;
    padding-bottom: 0;

    .media {
        top: 0;
        height: 100%;
        position: relative;
    }
}

.playBtnWrapper {
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    position: absolute;
    pointer-events: none;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;
    transition: getTransition();
    opacity: 0;
    visibility: hidden;

    &--beige button {
        background-color: $beige;
    }

    &--white button {
        background-color: $white;
    }

    &--smoke button {
        background-color: $smoke;
    }

    button {
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        align-content: center;
        align-items: center;
        justify-content: center;
        -webkit-appearance: none;
        border-radius: 100%;
        border: none;
        cursor: pointer;
        pointer-events: all;
        transition: getTransition();
        transform-origin: center;
        transform: scale(1.5);

        @include spacing('width', 's-64');
        @include spacing('height', 's-64');

        svg {
            fill: $black;
            @include spacing('font-size', 's-24');
        }
    }
}

.playBtnWrapperActive {
    opacity: 1;
    visibility: visible;

    button {
        transform: scale(1);
    }
}
