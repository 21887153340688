@use 'sass:math';

@import 'styles/scoped';

.bigHeadlineModule {
    @include spacing('padding-top', 's-120');
    @include spacing('padding-bottom', 's-40');

    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
    min-height: 100vh;

    @include mq(md) {
        @include spacing('padding-top', 's-200');
        @include spacing('padding-bottom', 's-80');

        text-align: center;
        min-height: 0;
        display: block;
    }
}

.leftCol {
    flex: 1 1 50%;
    padding: 0 percentage(1, 24) 0 0;

    @include mq(md) {
        @include spacing('margin-bottom', 's-56');

        flex: 1 1 100%;
    }
}

.rightCol {
    flex: 1 1 50%;
    padding: 0 percentage(1, 24);

    @include mq(md) {
        flex: 1 1 100%;
    }
}

.picture {
    @include spacing('padding-top', 's-64');
    @include spacing('padding-bottom', 's-64');

    width: 100%;
    display: block;
    position: relative;

    @include mq(md) {
        padding: 0;
    }
}

.pictureBg {
    background-color: $smoke;
    position: absolute;
    z-index: getZindex(default);
    top: 0;
    bottom: 0;
    left: percentage(2, 10);
    right: percentage(2, 10);
    border-radius: 370px;

    &white {
        background-color: $white;
    }

    @include mq(null, xl) {
        border-radius: get-vw(370px);
    }

    @include mq(md) {
        top: 50%;
        left: 0;
        right: auto;
        bottom: auto;
        transform: translateY(-50%);
        width: 100%;
        height: 0;
        padding-bottom: percentage(186, 328);
    }
}

.pictureBgParallax {
    position: relative;
    z-index: getZindex(default);
    padding-bottom: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.eyebrow {
    @include spacing('margin-bottom', 's-32');
    display: block;
    color: $red;
    font-weight: normal;
    text-transform: uppercase;
}

.title {
    display: block;
    max-width: rem(540px);
    @include spacing('margin-bottom', 's-24');

    @include mq(null, xl) {
        max-width: get-vw(540px);
    }

    @include mq(lg) {
        max-width: 100%;
    }
}

.description {
    display: block;
    max-width: rem(390px);

    @include mq(null, xl) {
        max-width: get-vw(390px);
    }

    @include mq(lg) {
        max-width: 100%;
    }
}

.btn {
    display: block;
    @include spacing('padding-top', 's-40');
}
