@import 'styles/scoped';
$form-input-placeholder-color: rgba($black, 0.8);

.main {
    display: block;
    width: 100%;
    background-color: $white;
    color: $black;

    @include spacing('padding-top', 's-80');
    @include spacing('padding-bottom', 's-120');
}

.mainNoHeader {
    @include spacing('padding-top', 's-160');

    @include mq(md) {
        @include spacing('padding-top', 's-264');
    }
}

.header {
}

.headerCol {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    padding-right: 20px;
    padding-top: 10px;
    @include spacing('padding-bottom', 's-40');

    &:last-child {
        padding-right: 0;

        @include mq(sm-md) {
            width: 100%;
        }
    }
}

.headerColNoPadding {
    padding-top: 0;
    padding-bottom: 0;
}

.headerColFullRow {
    flex-basis: 100%;
    padding-top: 0;
    padding-bottom: 0;

    .dropdownCol {
        @include spacing('padding-top', 's-8');
    }
}

.textInput {
    border-radius: 6px;
    background-color: $white;
    border: 1px solid $beige;

    @include mq(null, xl) {
        border-radius: get-vw(6px);
        border: get-vw(1px) solid $beige;
    }

    @include hover {
        border: 1px solid $beige;

        @include mq(null, xl) {
            border: get-vw(1px) solid $beige;
        }
    }

    &:focus,
    &:focus-within {
        outline: none;
        box-shadow: none;
        border: 1px solid $beige;

        @include mq(null, xl) {
            border: get-vw(1px) solid $beige;
        }
    }

    &::placeholder {
        color: $form-input-placeholder-color;
        opacity: 1;
    }
}

.textInput,
.dropdown {
    width: 240px;
    display: block;

    @include mq(null, xl) {
        width: get-vw(240px);
    }

    @include mq(sm-md) {
        width: 100%;
    }
}

.dropdownCol {
    @include spacing('padding-right', 's-8');

    position: relative;

    @include mq(sm-md) {
        @include spacing('margin-bottom', 's-8');

        padding-right: 0;
        width: 100%;
    }

    &:nth-child(2) {
        z-index: 100;
    }

    &:last-child {
        z-index: 90;
        padding-right: 0;
    }
}

.container {
    @include spacing('padding-top', 's-40');
    @include spacing('padding-bottom', 's-40');

    display: block;
    margin-left: -8px;
    margin-right: -8px;

    @include mq(null, xl) {
        margin-left: get-vw(-8px);
        margin-right: get-vw(-8px);
    }

    @include mq(sm-md) {
        margin-left: 0;
        margin-right: 0;
    }
}

.row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;
}

.col {
    @include spacing('margin-bottom', 's-32');

    display: block;
    width: percentage(1, 4);
    padding-left: 8px;
    padding-right: 8px;

    @include mq(null, xl) {
        padding-left: get-vw(8px);
        padding-right: get-vw(8px);
    }

    @include mq(lg) {
        width: percentage(1, 3);
    }

    @include mq(md) {
        width: percentage(1, 2);
    }

    @include mq(sm-md) {
        @include spacing('margin-bottom', 's-56');

        padding-left: 0;
        padding-right: 0;
        width: 100%;
    }
}

.emptyTitle {
    @include spacing('margin-top', 's-40');
    @include spacing('margin-bottom', 's-40');
}

.isLoading {
    opacity: 0.5;
    pointer-events: none !important;

    & * {
        pointer-events: none !important;
    }
}
