@use 'sass:math';

@import 'styles/scoped';

.verticalNumberedFeaturesModule {
    @include spacing('padding-top', 's-120');
    @include spacing('padding-bottom', 's-120');

    position: relative;
    z-index: getZindex(parallax, 1);

    @include mq(sm-md) {
        @include spacing('padding-top', 's-80');
        @include spacing('padding-bottom', 's-80');
    }
}

.header {
    @include spacing('margin-bottom', 's-16');

    @include mq(sm-md) {
        text-align: center;
    }
}

.eyebrow {
    @include spacing('margin-bottom', 's-32');

    color: $red;
    max-width: rem(360px);

    @include mq(null, xl) {
        max-width: get-vw(360px);
    }

    @include mq(sm-md) {
        margin-left: auto;
        margin-right: auto;
    }
}

.title {
    @include spacing('margin-bottom', 's-16');

    max-width: rem(420px);

    @include mq(null, xl) {
        max-width: get-vw(420px);
    }

    @include mq(sm-md) {
        margin-left: auto;
        margin-right: auto;
    }
}

.description {
    max-width: rem(360px);

    @include mq(null, xl) {
        max-width: get-vw(360px);
    }

    @include mq(sm-md) {
        @include spacing('margin-bottom', 's-64');

        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }
}

.content {
    display: flex;

    @include mq(sm-md) {
        display: block;
    }
}

.contentColumn {
    width: 50%;

    @include mq(sm-md) {
        width: 100%;
    }
}

.feature {
    padding: 0 percentage(1, 18) 0 percentage(4, 18);

    @include spacing('margin-bottom', 's-64');

    @include mq(lg) {
        padding: 0 percentage(1, 18);
    }

    &:last-child {
        margin-bottom: 0;
    }

    @include mq(sm-md) {
        @include spacing('margin-bottom', 's-40');

        padding: 0;
    }
}

.featureContent {
    position: relative;
}

.featureNumber {
    position: absolute;
    top: 0;
    left: get-vw(-60px);
    color: $red;

    @include mq(md) {
        position: static;
    }
}

.featureTitle {
    @include spacing('margin-bottom', 's-8');
}

.featureLink {
    @include spacing('margin-top', 's-16');

    @include mq(sm-md) {
        @include spacing('margin-top', 's-32');
    }
}

.sectionBackgrounds {
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    top: 0;
    left: 0;
    z-index: getZindex(parallax);
}

.sectionBackground {
    padding-bottom: percentage(444, 1440);
    position: absolute;
    display: block;
    width: rem(180px);
    background-size: contain;
    background-repeat: no-repeat;

    @include mq(null, xl) {
        width: get-vw(180px);
    }

    @include mq(lg-xl) {
        max-width: rem(160px);
    }

    @include mq(md) {
        display: none;
    }

    &:first-child {
        left: 0;
        bottom: 0;
        background-position: left;
    }

    &:last-child {
        right: 0;
        top: 0;
        background-position: right;
    }
}
