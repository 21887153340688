@use 'sass:math';

@import 'styles/scoped';

.main {
    width: 100%;
    display: block;
    position: relative;
}

.mediaWrapper {
    height: 0;
    padding-bottom: percentage(700, 1440);
    overflow: hidden;
    position: relative;
    z-index: getZindex(default);
}

.media {
    position: absolute;
    width: 100%;
    height: 110%;
    top: -10%;
    left: 0;

    img,
    video {
        width: 100%;
        height: auto;
        object-fit: cover;
    }
}

.background {
    height: 0;
    z-index: getZindex(default);
    width: 100%;
    display: block;
    position: relative;
    padding-bottom: 22%;
    overflow: hidden;
}

.backgroundImage {
    position: absolute;
    top: -10%;
    left: 0;
    width: 100%;
    height: 150%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    display: block;
}

.parallaxImageLeft {
    @include spacing('left', 's-24');
    z-index: getZindex(default, 1);
    bottom: 20vw;
    width: 16vw;
    height: 19vw;
    display: block;
    position: absolute;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    transform: rotate(-10deg);

    @include mq(md) {
        display: none;
    }
}

.parallaxImageRight {
    z-index: getZindex(default, 1);
    right: 5%;
    bottom: -10vw;
    width: 16vw;
    height: 19vw;
    display: block;
    position: absolute;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    transform: rotate(10deg);

    @include mq(md) {
        display: none;
    }
}

.playBtnWrapper {
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    position: absolute;
    pointer-events: none;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;
    transition: getTransition();
    opacity: 0;
    visibility: hidden;

    &--beige button {
        background-color: $beige;
    }

    &--white button {
        background-color: $white;
    }

    &--smoke button {
        background-color: $smoke;
    }

    button {
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        align-content: center;
        align-items: center;
        justify-content: center;
        -webkit-appearance: none;
        border-radius: 100%;
        border: none;
        cursor: pointer;
        pointer-events: all;
        transition: getTransition();
        transform-origin: center;
        transform: scale(1.5);

        @include spacing('width', 's-64');
        @include spacing('height', 's-64');

        svg {
            fill: $black;
            @include spacing('font-size', 's-24');
        }
    }
}

.playBtnWrapperActive {
    opacity: 1;
    visibility: visible;

    button {
        transform: scale(1);
    }
}
