@import 'styles/scoped';

.presentationalProductList {
    width: 100%;
    height: 100vh;
    position: relative;

    @include mq(md) {
        @include spacing('padding-top', 's-160');
        @include spacing('padding-bottom', 's-80');
        height: auto;
    }
}

.topWave {
    z-index: getZindex(default);
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: get-vh(370px);
    background-color: $smoke;

    @include mq(md) {
        height: rem(180px);
    }
}

.topWaveShape {
    display: block;
    width: 100%;
    height: 0;
    top: 100%;
    left: 0;
    position: absolute;
}

.imageSlider {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: getZindex(default);

    @include mq(md) {
        @include spacing('margin-bottom', 's-32');
        height: auto;
    }

    :global {
        .swiper-container {
            @include mq(md) {
                overflow: visible;
            }
        }

        .swiper-wrapper {
            transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1) !important;
        }
    }
}

.imageSliderSlide {
    width: 100%;
    height: 100vh;
    transition: getTransition();
    display: flex;
    align-items: center;
    cursor: grab;

    @include mq(md) {
        height: auto;
    }

    &:global {
        &.swiper-slide-active {
            :local {
                .mainImage {
                    transform: rotate(0deg);
                }

                .mainImageBack {
                    transform: translateX(0vw);
                }

                .secondaryImage {
                    transform: translateX(-5vw) rotate(35deg);
                }
            }
        }
    }
}

.mainImage {
    width: percentage(9, 24);
    height: 0;
    padding-bottom: percentage(585, 1440);
    margin-left: percentage(2, 24);
    transform: rotate(20deg);
    transition: getCustomTransition(transform 1.7s cubic-bezier(0.25, 0.1, 0.25, 1));
    display: block;

    @include mq(md) {
        width: 100%;
        margin: 0;
        padding-bottom: 100%;
    }
}

.mainImageFront,
.mainImageBack {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: block;
}

.mainImageFront {
    z-index: getZindex(default, 1);
}

.mainImageBack {
    z-index: getZindex(default);
    transform: translateX(15vw);
    transition: getCustomTransition(transform 1.7s cubic-bezier(0.25, 0.1, 0.25, 1));
}

.secondaryImage {
    width: get-vw(240px);
    height: get-vw(240px);
    position: absolute;
    bottom: percentage(140, 800);
    left: 0;
    transform: translateX(50vw) rotate(35deg);
    transition: getCustomTransition(transform 1.7s cubic-bezier(0.25, 0.1, 0.25, 1));
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    filter: blur(1px);

    @include mq(md) {
        display: none;
    }
}

.imageSliderParallaxBg {
    position: absolute;
    left: 0;
    top: 10%;
    width: 300%;
    height: 76%;
    background-size: contain;
    background-position: left;
    display: block;
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1) !important;

    @include mq(lg) {
        height: 100%;
        top: 0;
    }

    @include mq(md) {
        display: none;
    }
}

.contentSlider {
    position: absolute;
    width: percentage(8, 24);
    top: 50%;
    right: percentage(3, 24);
    transform: translateY(-50%);
    z-index: getZindex(default, 1);

    :global {
        .swiper-container {
            overflow: visible;
        }

        .swiper-wrapper {
            align-items: center;

            @include mq(md) {
                align-items: flex-start;
            }
        }
    }

    @include mq(md) {
        position: relative;
        top: 0;
        left: 0;
        transform: none;
        width: 100%;
        text-align: center;
    }
}

.contentSliderSlide {
    cursor: grab;

    &:global {
        &.swiper-slide-active {
            z-index: getZindex(default);

            :local {
                .contentSliderHeader {
                    opacity: 1;
                    transform: translateX(0vw);
                    transition-delay: 0.8s;
                }

                .contentSliderTitle {
                    opacity: 1;
                    transform: translateX(0vw);
                    transition-delay: 0.9s;
                }

                .contentSliderDescription {
                    opacity: 1;
                    transform: translateX(0vw);
                    transition-delay: 1s;
                }

                .contentSliderButton {
                    opacity: 1;
                    transform: translateX(0vw);
                    transition-delay: 1.1s;
                }
            }
        }

        &.swiper-slide-next {
            :local {
                .contentSliderHeader {
                    transform: translateX(get-vw(70px));
                }

                .contentSliderTitle {
                    transform: translateX(get-vw(60px));
                }

                .contentSliderDescription {
                    transform: translateX(get-vw(60px));
                }

                .contentSliderButton {
                    transform: translateX(get-vw(40px));
                }
            }
        }

        &.swiper-slide-prev {
            :local {
                .contentSliderHeader {
                    transform: translateX(get-vw(-70px));
                }

                .contentSliderTitle {
                    transform: translateX(get-vw(-60px));
                }

                .contentSliderDescription {
                    transform: translateX(get-vw(-60px));
                }

                .contentSliderButton {
                    transform: translateX(get-vw(-40px));
                }
            }
        }
    }
}

.contentSliderWrapper {
    max-width: rem(330px);

    @include mq(null, xl) {
        max-width: get-vw(330px);
    }

    @include mq(md) {
        max-width: rem(230px);
        margin: 0 auto;
    }
}

.contentSliderHeader {
    @include spacing('margin-bottom', 's-24');

    display: flex;
    align-items: flex-start;
    opacity: 0;
    transform: translateX(get-vw(-70px));
    transition: getCustomTransition(all 1s cubic-bezier(0.25, 0.1, 0.25, 1));

    @include mq(md) {
        align-items: center;
        justify-content: center;
    }
}

.contentSliderFraction {
    color: $red;
}

.contentSliderTitle {
    @include spacing('margin-bottom', 's-16');
    opacity: 0;
    transform: translateX(get-vw(-60px));
    transition: getCustomTransition(all 1s cubic-bezier(0.25, 0.1, 0.25, 1));
    transition-delay: 0.1s;
}

.contentSliderDescription {
    @include spacing('margin-bottom', 's-24');

    opacity: 0;
    transform: translateX(get-vw(-60px));
    transition: getCustomTransition(all 1s cubic-bezier(0.25, 0.1, 0.25, 1));
    transition-delay: 0.2s;
    max-width: rem(300px);

    @include mq(null, xl) {
        max-width: get-vw(300px);
    }
}

.contentSliderButton {
    opacity: 0;
    transform: translateX(get-vw(-40px));
    transition: getCustomTransition(all 1s cubic-bezier(0.25, 0.1, 0.25, 1));
    transition-delay: 0.3s;
}

.arrows {
    position: absolute;
    top: 50%;
    transform: translateY(-50%) translateX(50%);
    right: 0;
    display: flex;
    flex-direction: column-reverse;
    z-index: getZindex(default);

    @include mq(lg) {
        transform: translateY(-50%) translateX(100%);
    }

    @include mq(md) {
        top: 10%;
        left: 0;
        transform: translateY(-10%) translateX(0%);
        width: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
}

.arrow {
    &:first-child {
        @include spacing('margin-top', 's-16');

        @include mq(md) {
            margin: 0;
        }
    }
}

.customPagination {
    @include spacing('bottom', 's-40');

    position: absolute;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: getZindex(default, 2);

    @include mq(md) {
        display: none;
    }
}

.customPaginationBullet {
    @include spacing('margin-left', 's-32');
    @include spacing('margin-right', 's-32');

    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
    display: block;
    cursor: pointer;
    position: relative;
    border: none;
    border-radius: 0;
    -webkit-appearance: none;
    background-color: transparent;

    &:before {
        content: '';
        position: absolute;
        top: 115%;
        left: 0;
        width: 100%;
        height: rem(2px);
        background: $red;
        display: block;
        transition: getCustomTransition(opacity 0.7s cubic-bezier(0.25, 0.1, 0.25, 1));
        opacity: 0;

        @include mq(null, xl) {
            height: get-vw(2px);
        }
    }

    &:global {
        &.swiper-pagination-bullet-active {
            color: $red;
            pointer-events: none;

            &:before {
                opacity: 1;
            }
        }
    }
}
