@import 'styles/scoped';

.main {
    background-color: $white;

    @include spacing('padding-top', 's-200');
    @include spacing('padding-bottom', 's-120');

    @include mq(md) {
        @include spacing('padding-top', 's-264');
        @include spacing('padding-bottom', 's-64');
    }
}

.title {
    @include spacing('margin-bottom', 's-64');
}
