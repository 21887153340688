@use 'sass:math';

@import 'styles/scoped';

.verticalImageTestimonialSliderModule {
    @include spacing('padding-top', 's-120');
    @include spacing('padding-bottom', 's-120');

    display: flex;
    align-items: center;

    @include mq(sm-md) {
        @include spacing('padding-top', 's-80');
        @include spacing('padding-bottom', 's-80');

        flex-direction: column;
    }
}

.content {
    width: percentage(11, 18);
    padding-right: percentage(1, 18);
    display: flex;

    @include mq(sm-md) {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
        order: 2;
    }

    .contentRight & {
        order: 2;
        padding-right: 0;
        justify-content: flex-end;

        @include mq(lg) {
            padding-left: percentage(1, 18);
        }

        @include mq(sm-md) {
            padding-left: 0;
            justify-content: flex-start;
        }
    }
}

.contentInner {
    width: 100%;
    max-width: rem(400px);

    @include mq(null, xl) {
        max-width: get-vw(480px);
    }

    @include mq(sm-md) {
        max-width: 100%;
    }
}

.eyebrow {
    @include spacing('margin-bottom', 's-32');

    color: $red;
    margin-left: get-vw(-60px);

    @include mq(lg) {
        margin-left: 0;
    }
}

.title {
    @include spacing('margin-bottom', 's-80');

    max-width: rem(320px);
    margin-left: get-vw(-60px);

    @include mq(null, xl) {
        max-width: get-vw(320px);
    }

    @include mq(lg-xl) {
        max-width: rem(420px);
    }

    @include mq(lg) {
        @include spacing('margin-bottom', 's-56');

        margin-left: auto;
        margin-right: auto;
    }
}

.testimonialSliderWrapper {
    position: relative;
}

.icon {
    position: absolute;
    top: 0;
    left: get-vw(-60px);
    font-size: get-vw(26px);
    color: $red;

    @include mq(lg) {
        display: none;
    }

    @include mq(sm-md) {
        @include spacing('margin-bottom', 's-32');

        display: block;
        position: static;
        font-size: rem(24px);
    }
}

.testimonialSliderSlide {
    transition: getCustomTransition(opacity);
    cursor: grab;

    &:global {
        &.swiper-slide-next,
        &.swiper-slide-prev {
            opacity: 0;
        }

        &.swiper-slide-active {
            opacity: 1;
        }
    }
}

.testimonialSliderHeader {
    @include spacing('margin-bottom', 's-32');

    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $faded;
}

.testimonialSliderTitle {
    @include spacing('margin-bottom', 's-4');
}

.images {
    width: percentage(7, 18);
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    @include mq(sm-md) {
        @include spacing('margin-bottom', 's-64');

        width: 100%;
        padding-left: 0;
        padding-right: 0;
        order: 1;
        flex-direction: column;
    }

    .contentRight & {
        order: 1;
    }
}

.arrows {
    @include spacing('padding-top', 's-80');

    @include mq(sm-md) {
        @include spacing('padding-top', 's-56');
    }
}

.arrow {
    &:first-child {
        @include spacing('margin-right', 's-16');
    }
}

.desktopTitle {
    @include mq(sm-md) {
        display: none;
    }
}

.mobileTitle {
    display: none;
    text-align: center;

    @include mq(sm-md) {
        display: block;
    }
}

.normalImage {
    &Desktop {
        display: block;

        @include mq(sm-md) {
            display: none;
        }
    }

    &Mobile {
        display: none;

        @include mq(sm-md) {
            display: block;
        }
    }
}
