@import 'styles/scoped';

.box {
    display: block;
    width: 100%;
    color: $black;
    background-color: $beige;
    border-radius: 6px;

    @include spacing('padding', 's-56');

    @include mq(null, xl) {
        border-radius: get-vw(6px);
    }
}

.title {
    display: block;

    @include spacing('padding-bottom', 's-16');
}

.person {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: stretch;
    align-content: stretch;
    justify-content: flex-start;

    @include spacing('margin-top', 's-40');
}

.figure {
    @include spacing('padding-right', 's-24');

    display: block;
    flex: 0 0 rem(80px);

    @include mq(null, xl) {
        flex: 0 0 get-vw(80px);
    }

    img {
        display: none;
    }
}

.image {
    height: 0;
    width: 100%;
    display: block;
    padding-bottom: 150%;
    border-radius: 66px;
    background-color: $white;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    @include mq(null, xl) {
        border-radius: get-vw(66px);
    }
}

.personDesc {
    display: flex;
    flex: 1 1 auto;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
}

.personName {
    display: block;
    font-weight: 500;
}

.personSport {
    display: block;
    text-transform: uppercase;
    color: $faded;

    @include spacing('margin-top', 's-8');
}

.btn {
    display: block;

    @include spacing('margin-top', 's-40');
}
