@import 'styles/scoped';

.main {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
    color: $black;

    li {
        a {
            outline: none;

            &:focus,
            &:active {
                outline: none;
            }
        }
    }

    li:first-child {
        @include spacing('margin-right', 's-40');

        @include mq(md) {
            @include spacing('margin-right', 's-16');
        }
    }

    li:last-child {
        @include spacing('margin-left', 's-40');

        @include mq(md) {
            @include spacing('margin-left', 's-16');
        }
    }
}

.label {
    margin: 0;
    padding: 0;
    cursor: pointer;
    display: block;
    background-color: transparent;
    border: none;
    border-radius: 0;
    -webkit-appearance: none;
    color: $black;
}

.labelDisabled {
    opacity: 0.2;
    pointer-events: none;
}

.number {
    a {
        @include spacing('width', 's-40');
        @include spacing('height', 's-40');

        margin: 0;
        padding: 0;
        cursor: pointer;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        background-color: transparent;
        border: 2px solid transparent;
        border-radius: 100%;
        -webkit-appearance: none;
        color: $black;
        outline: none;
        transition: getTransition();

        @include mq(null, xl) {
            border: get-vw(2px) solid transparent;
        }

        @include hover {
            color: $red;
        }

        &:focus {
            outline: none;
        }

        &[disabled] {
            pointer-events: none;
            color: $black !important;
            border: 2px solid transparent !important;

            @include mq(null, xl) {
                border: get-vw(2px) solid transparent !important;
            }
        }
    }
}

.numberActive {
    a {
        pointer-events: none;
        color: $red;
        border: 2px solid $red;

        @include mq(null, xl) {
            border: get-vw(2px) solid $red;
        }
    }
}

.disabled {
    opacity: 0.5;
    pointer-events: none !important;

    & * {
        pointer-events: none !important;
    }
}
