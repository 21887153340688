@import 'styles/scoped';

.box {
    display: block;
    line-height: 1;
}

.vertical {
    @include spacing('padding-top', 's-12');
    @include spacing('padding-bottom', 's-12');

    display: block;
    border-radius: 6px;
    background-color: $beige;
    text-align: center;

    @include mq(null, xl) {
        border-radius: get-vw(6px);
    }

    &.transparent {
        background-color: transparent;
    }

    li {
        @include spacing('padding-top', 's-12');
        @include spacing('padding-bottom', 's-12');
        @include spacing('padding-left', 's-8');
        @include spacing('padding-right', 's-8');
    }
}

.horizontal {
    @include spacing('padding-top', 's-12');
    @include spacing('padding-bottom', 's-12');

    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;

    li {
        @include spacing('padding-top', 's-8');
        @include spacing('padding-bottom', 's-8');
        @include spacing('padding-left', 's-12');
        @include spacing('padding-right', 's-12');
    }
}

.btn {
    display: inline-block;
    padding: 0;
    margin: 0;
    cursor: pointer;
    background-color: transparent;
    border: none;
    color: $black;
    border-radius: 0;
    -webkit-appearance: none;
    text-align: center;
    transition: getTransition();
    font-size: rem(16px);

    @include mq(null, xl) {
        font-size: get-vw(16px);
    }

    @include hover {
        color: $red;
    }
}
