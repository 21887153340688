@use 'sass:math';

@import 'styles/scoped';

.testimonial {
    @include hover {
        .figuresOverlay {
            opacity: 1;
            visibility: visible;
        }
    }
}

.figures {
    @include spacing('margin-bottom', 's-32');

    position: relative;
    padding-bottom: percentage(740, 420);
    cursor: pointer;

    @include mq(sm-md) {
        padding-bottom: percentage(186, 328);
    }
}

.figuresUnderlay,
.figuresOverlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.figuresOverlay {
    z-index: getZindex('default');
    opacity: 0;
    visibility: hidden;
    transition: getTransition('default');
}

.figureDesktop {
    display: block;

    @include mq(sm-md) {
        display: none;
    }
}

.figureMobile {
    display: none;

    @include mq(sm-md) {
        display: block;
    }
}

.title {
    @include spacing('margin-bottom', 's-8');

    display: block;
    cursor: pointer;

    :global {
        .u-icon--videoindicator {
            vertical-align: middle;
            height: get-vw(23px);
            @include spacing('margin-left', 's-8');

            @include mq(lg) {
                height: 23px;
            }
        }
    }
}

.productTitle {
    color: $red;
}

.center {
    text-align: center;
}
