@use 'sass:math';

@import 'styles/scoped';

.verticalImageFeaturedModule {
    @include spacing('padding-top', 's-120');
    @include spacing('padding-bottom', 's-120');

    display: flex;
    align-items: center;

    @include mq(sm-md) {
        @include spacing('padding-top', 's-80');
        @include spacing('padding-bottom', 's-80');

        flex-direction: column;
    }
}

.content {
    width: percentage(11, 18);
    padding-right: percentage(1, 18);
    display: flex;

    @include mq(sm-md) {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
        order: 2;
    }

    .contentRight & {
        order: 2;
        padding-right: 0;
        justify-content: flex-end;

        @include mq(lg) {
            padding-left: percentage(1, 18);
        }

        @include mq(sm-md) {
            padding-left: 0;
            justify-content: flex-start;
        }
    }
}

.contentInner {
    max-width: rem(420px);

    @include mq(null, xl) {
        max-width: get-vw(420px);
    }

    @include mq(sm-md) {
        max-width: 100%;
        width: 100%;
    }
}

.eyebrow {
    @include spacing('margin-bottom', 's-32');

    color: $red;

    @include mq(sm-md) {
        text-align: center;
    }
}

.title {
    @include spacing('margin-bottom', 's-16');

    max-width: rem(420px);

    @include mq(null, xl) {
        max-width: get-vw(420px);
    }

    @include mq(sm-md) {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }
}

.description {
    max-width: rem(420px);

    @include mq(null, xl) {
        max-width: get-vw(420px);
    }

    @include mq(sm-md) {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }
}

.logos {
    @include spacing('margin-bottom', 's-40');

    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
    max-width: rem(420px);

    @include mq(null, xl) {
        max-width: get-vw(420px);
    }

    @include mq(sm-md) {
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
    }

    li {
        @include spacing('margin-right', 's-24');

        width: 33.333%;
    }

    img {
        width: 100%;
    }
}

.accordion {
    @include spacing('margin-top', 's-40');
}

.link {
    @include spacing('margin-top', 's-32');
}

.images {
    width: percentage(7, 18);
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    .contentRight & {
        order: 1;
    }

    &Desktop {
        display: block;

        @include mq(sm-md) {
            display: none;
        }
    }

    &Mobile {
        width: 100%;
        display: none;
        padding-left: 0;
        padding-right: 0;
        order: 1;
        flex-direction: column;
        @include spacing('margin-bottom', 's-64');

        @include mq(sm-md) {
            display: block;
        }
    }
}

.buttons {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
    @include spacing('gap', 's-8');

    @include mq(sm-md) {
        justify-content: center;
    }
}

.video {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;
    position: absolute;
    z-index: 10;
}
