@import 'styles/scoped';

.main {
    display: block;
    width: 100%;
    background-color: $white;
    color: $black;

    @include spacing('padding-top', 's-80');
    @include spacing('padding-bottom', 's-120');
}

.mainNoHeader {
    @include spacing('padding-top', 's-160');

    @include mq(md) {
        @include spacing('padding-top', 's-264');
    }
}

.header {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: space-between;
}

.headerCol {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;

    &:last-child {
        padding-right: 0;
    }
}

.headerColNoPadding {
    padding-top: 0;
    padding-bottom: 0;
}

.dropdown {
    z-index: 5;
    width: 240px;
    display: block;
    position: relative;

    @include mq(null, xl) {
        width: get-vw(240px);
    }
}

.dropdownCol {
    padding-top: 5px;
    padding-right: 8px;
    padding-bottom: 5px;

    &:last-child {
        padding-right: 0;
    }
}

.listsort {
    display: block;
    padding-top: 15px;
    padding-bottom: 15px;

    @include spacing('padding-right', 's-32');
}

.listinline {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-content: center;

    li {
        padding-right: 8px;

        &:last-child {
            padding-right: 0;
        }
    }
}

.btnsort {
    display: inline-block;
    color: $black;
    margin: 0;
    padding: 0;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    -webkit-appearance: none;
    border: none;
    border-radius: 0;
    vertical-align: top;
    transition: getCustomTransition(color);
    outline: none;

    &::before {
        content: '\A';
        position: absolute;
        width: 120%;
        height: 200%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 100%;
        height: 2px;
        width: 100%;
        background-color: $red;
        z-index: getZindex('default', 1);
        transform: scaleX(0);
        transform-origin: left;
    }

    @include hover {
        color: $red;

        &::after {
            transition: getCustomTransition(transform 0.3s);
            transform: scaleX(1);
        }
    }

    &:focus,
    &:active {
        outline: none;
    }
}

.btnactive {
    color: $red;

    &::after {
        transform: scaleX(1);
    }
}

.isLoading {
    opacity: 0.5;
    pointer-events: none !important;

    & * {
        pointer-events: none !important;
    }
}
