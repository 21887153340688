@import 'styles/scoped';

.instagramModule {
    @include spacing('padding-top', 's-120');
    @include spacing('padding-bottom', 's-120');

    @include mq(md) {
        @include spacing('padding-top', 's-80');
        @include spacing('padding-bottom', 's-80');
    }
}

.header {
    @include spacing('padding-bottom', 's-64');

    color: $white;
}

.eyebrow {
    @include spacing('margin-bottom', 's-24');
}

.title {
    @include spacing('margin-bottom', 's-32');
}

.content {
    display: flex;
    flex-wrap: wrap;
    margin-left: rem(-16px);
    margin-right: rem(-16px);

    @include mq(null, xl) {
        margin-left: get-vw(-16px);
        margin-right: get-vw(-16px);
    }

    @include mq(md) {
        margin-left: rem(-8px);
        margin-right: rem(-8px);
        margin-bottom: rem(-8px);
    }
}

.post {
    width: 20%;
    padding-left: rem(16px);
    padding-right: rem(16px);

    @include mq(null, xl) {
        padding-left: get-vw(16px);
        padding-right: get-vw(16px);
    }

    @include mq(md) {
        width: 50%;
        padding-left: rem(8px);
        padding-right: rem(8px);
        padding-bottom: rem(16px);
    }
}

.postInner {
    padding-bottom: 100%;
    width: 100%;
    display: block;
    height: 0;
    position: relative;
    overflow: hidden;
    border-radius: 6px;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);

    @include mq(null, xl) {
        border-radius: get-vw(6px);
    }

    @include hover {
        .postImage {
            transform: scale(1.1);
        }
    }
}

.postImage {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: getTransition('zoom');
    transform-origin: center;
    -webkit-backface-visibility: hidden; /* add to fix webkit bug jitter */
    -webkit-transform: perspective(1000px); /* add to fix webkit bug jitter */
}

.postIcon {
    @include spacing('right', 's-8');
    @include spacing('bottom', 's-8');

    font-size: rem(18px);
    color: $white;
    position: absolute;

    @include mq(null, xl) {
        font-size: get-vw(18px);
    }
}
