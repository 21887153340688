@import 'styles/scoped';

.statisticsModule {
    @include spacing('padding-top', 's-160');
    @include spacing('padding-bottom', 's-160');

    @include mq(sm-md) {
        @include spacing('padding-top', 's-80');
        @include spacing('padding-bottom', 's-80');
    }
}

.header {
    @include spacing('margin-bottom', 's-64');

    @include mq(sm-md) {
        @include spacing('margin-bottom', 's-56');
    }
}

.title {
    @include spacing('margin-bottom', 's-16');

    max-width: rem(720px);
    margin-left: auto;
    margin-right: auto;

    @include mq(null, xl) {
        max-width: get-vw(720px);
    }
}

.description {
    max-width: rem(420px);
    margin-left: auto;
    margin-right: auto;

    @include mq(null, xl) {
        max-width: get-vw(420px);
    }
}

.content {
    display: flex;
    flex-wrap: wrap;
    margin-left: get-vw(-8px);
    margin-right: get-vw(-8px);

    @include mq(sm-md) {
        flex-direction: column;
        margin-left: 0;
        margin-right: 0;
    }
}

.statistic {
    @include spacing('padding', 's-8');

    width: 50%;

    @include mq(sm-md) {
        width: 100%;
        padding-left: 0;
        padding-right: 0;

        &:first-child {
            padding-top: 0;
        }

        &:last-child {
            padding-bottom: 0;
        }
    }
}

.statisticInner {
    @include spacing('padding-top', 's-64');
    @include spacing('padding-bottom', 's-40');
    @include spacing('padding-left', 's-40');
    @include spacing('padding-right', 's-40');

    background: $beige;
    height: 100%;
    border-radius: 6px;

    @include mq(null, xl) {
        border-radius: get-vw(6px);
    }

    &.lightBeige {
        background-color: $smoke;
    }
}

.statisticNumber {
    @include spacing('margin-bottom', 's-16');

    color: $red;
}

.statisticUnit {
    @include spacing('margin-left', 's-4');
}

.statisticExplanation {
    max-width: rem(420px);

    @include mq(null, xl) {
        max-width: get-vw(420px);
    }
}

.disclaimer {
    @include spacing('margin-top', 's-8');

    max-width: rem(420px);
    margin-left: auto;

    @include mq(null, xl) {
        max-width: get-vw(420px);
    }
}
