@use 'sass:math';

@import 'styles/scoped';

.main {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    min-height: 100vh;
    align-items: center;
    align-content: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    transition: getTransition();
    pointer-events: none;
    padding: percentage(1, 24);

    &--opened {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    }

    &--close {
        opacity: 0;
        visibility: hidden;
        transform: translateY(-25px);
    }
}

.overlay {
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    z-index: getZindex(modal);
    position: fixed;
    opacity: 0;
    visibility: hidden;
    transition: getTransition();
    background-color: $black-40;

    &--opened {
        opacity: 1;
        visibility: visible;
    }

    &--close {
        opacity: 0;
        visibility: hidden;
    }
}

.container {
    @include spacing('padding', 's-64');

    width: 100%;
    display: block;
    max-width: get-vw(720px);
    pointer-events: all;
    background-color: $white;
    border-radius: get-vw(10px);
    text-align: center;
    position: relative;
    transform: translateY(-25px);
    transition: getTransition();
    opacity: 0;
    visibility: hidden;

    @include mq(lg) {
        max-width: get-vw(900px);
    }

    @include mq(md) {
        max-width: 100%;
    }
}

.containerOpened {
    opacity: 1;
    visibility: visible;
    transform: translateY(0px);
}

.btnClose {
    color: $black;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-end;
    align-content: center;
    align-items: center;
    -webkit-appearance: none;
    margin: 0;
    padding: 0;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
    background-color: transparent;
    white-space: nowrap;
    position: absolute;
    top: 0;
    right: 0;
    @include spacing('padding', 's-24');

    :global {
        .u-icon {
            transform: rotate(0deg);
            will-change: transform;
            transition: getTransition();
        }
    }

    &:hover :global {
        .u-icon {
            transform: rotate(-90deg);
        }
    }
}

.btnIcon {
    width: get-vw(15px);
    height: get-vw(15px);

    @include mq(md) {
        width: 15px;
        height: 15px;
    }
}

.title {
    @include spacing('margin-bottom', 's-40');
}

.radioList {
    display: block;
    @include spacing('margin-bottom', 's-32');

    li {
        @include spacing('padding', 's-4');

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.radioItem {
    display: block;

    input[type='radio'] {
        display: none;
    }

    &Label {
        width: 100%;
        display: flex;
        cursor: pointer;
        text-align: left;
        background-color: $beige;
        border-radius: get-vw(10px);
        @include spacing('padding', 's-24');
    }

    &Bullet {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        align-content: center;
        width: get-vw(32px);
        border-radius: 100%;
        height: get-vw(32px);
        background-color: $white;
        border: 1px solid $maize;
        @include spacing('margin-right', 's-16');

        &:before {
            width: get-vw(14px);
            height: get-vw(14px);
            background-color: $black;
            transform-origin: center;
            border-radius: 100%;
            content: '\A';
            opacity: 0;
            visibility: visible;
            transform: scale(0);
            transition: getTransition();
        }
    }

    &Active {
        .radioItemBullet:before {
            opacity: 1;
            visibility: visible;
            transform: scale(1);
        }
    }
}

.image {
    width: 100%;
    display: block;
    max-width: get-vw(270px);
    margin-left: auto;
    margin-right: auto;
    @include spacing('margin-bottom', 's-32');

    @include mq(md) {
        max-width: 270px;
    }

    img {
        width: 100%;
        display: block;
        border-radius: get-vw(6px);
    }
}

.productTitle {
    display: block;
    @include spacing('margin-bottom', 's-8');
}

.productDescription {
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: get-vw(510px);
    @include spacing('margin-bottom', 's-32');

    @include mq(md) {
        max-width: 100%;
    }
}

.productLinks {
    margin-left: auto;
    margin-right: auto;
    @include spacing('margin-bottom', 's-24');
}

.productButtons {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
    align-content: stretch;
    align-items: stretch;

    @include mq(sm-md) {
        display: block;
    }

    li {
        @include mq(sm-md) {
            margin-bottom: 8px;
        }
    }
}

.productBtnLeft {
    border-radius: 60px 0 0 60px;

    @include mq(sm-md) {
        border-radius: 60px;
    }

    @include mq(null, xl) {
        border-radius: get-vw(60px) 0 0 get-vw(60px);
    }
}

.productBtnRight {
    border-radius: 0 60px 60px 0;

    @include mq(sm-md) {
        border-radius: 60px;
    }

    @include mq(null, xl) {
        border-radius: 0 get-vw(60px) get-vw(60px) 0;
    }
}

.loader {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    position: absolute;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;
    transition: getTransition('slide');
    opacity: 0;
    visibility: hidden;
    transform: scale(0.4);

    svg {
        display: block;
        width: get-vw(160px);
        height: get-vw(160px);
        transform-origin: center;
        animation: rotate-center 1.4s linear infinite both;
        -webkit-animation: rotate-center 1.4s linear infinite both;

        @include mq(sm) {
            width: 80px;
            height: 80px;
        }
    }
}

.loaderOpened {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
}

@-webkit-keyframes rotate-center {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes rotate-center {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
