@use 'sass:math';

@import 'styles/scoped';

.section {
    background-size: cover;
    margin-bottom: -90px;
    z-index: 6;

    @include mq(null, xl) {
        margin-bottom: get-vw(-90px);
    }

    @include mq(md) {
        margin-bottom: 0;
        background-position-x: 50%;
        @include spacing('padding-bottom', 's-120');
    }

    @include mq(sm) {
        background-position-x: 67%;
    }
}

.container {
    @include spacing('padding-top', 's-120');
    @include spacing('padding-bottom', 's-40');
}

.columns {
    display: flex;

    @include mq(md) {
        display: block;
    }

    &Item {
        width: 50%;

        @include mq(md) {
            width: 100%;
        }
    }
}

.rightColumn {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
        max-width: 90%;
        @include spacing('margin-bottom', 's-40');
    }

    @include mq(md) {
        display: block;

        img {
            margin-left: auto;
            margin-right: auto;
            max-width: percentage(223, 390);
        }
    }
}
