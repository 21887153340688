@use 'sass:math';

@import 'styles/scoped';

.section {
    @include spacing('padding-top', 's-120');
    @include spacing('padding-bottom', 's-120');
}

.title {
    @include spacing('margin-bottom', 's-80');

    display: block;
}

.wrapper {
    display: block;
}

.athletes {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: center;
    margin-left: rem(-16px);
    margin-right: rem(-16px);

    @include mq(null, xl) {
        margin-left: get-vw(-16px);
        margin-right: get-vw(-16px);
    }
}

.athlete {
    width: percentage(1, 3);
    display: block;
    padding-left: rem(16px);
    padding-right: rem(16px);

    @include mq(null, xl) {
        padding-left: get-vw(16px);
        padding-right: get-vw(16px);
    }

    @include mq(md) {
        width: 100%;
        padding: 0;
    }
}

.athletesSliders {
    :global {
        .swiper-wrapper {
            align-items: center;
        }

        .swiper-container {
            overflow: visible;
        }

        .swiper-slide {
            box-sizing: border-box;
            cursor: grab;
        }
    }
}

.scrollbar {
    @include spacing('margin-top', 's-64');

    width: 100%;
    display: block;
}

.scrollbarItem {
    width: 100%;
    height: 3px;
    display: block;
    position: relative;
    background-color: $beige;
    overflow: hidden;
    text-indent: -9999px;
    z-index: getZindex(shapes);

    @include mq(null, xl) {
        height: get-vw(3px);
    }

    & > div {
        display: block;
        top: 0;
        left: 0;
        height: 100%;
        border-radius: 40px;
        position: absolute;
        background-color: $red;
        cursor: move;

        @include mq(null, xl) {
            border-radius: get-vw(40px);
        }
    }
}
