@use 'sass:math';

@import 'styles/scoped';

.nextProductModule {
    @include spacing('padding-top', 's-80');
    @include spacing('padding-bottom', 's-80');

    display: flex;
    align-items: center;

    @include mq(sm-md) {
        flex-direction: column;
        text-align: center;
    }
}

.content {
    position: relative;
    z-index: getZindex(default);
    width: 52%;

    @include mq(sm-md) {
        margin-bottom: 0;

        width: 100%;
    }
}

.eyebrow {
    @include spacing('margin-bottom', 's-32');

    color: $red;
}

.holder {
    @include spacing('padding-top', 's-64');
    @include spacing('padding-bottom', 's-64');

    position: relative;

    @include mq(sm-md) {
        padding: 0;
    }
}

.title {
    @include spacing('margin-bottom', 's-16');

    max-width: rem(480px);

    @include mq(null, xl) {
        max-width: get-vw(480px);
    }

    @include mq(sm-md) {
        margin-left: auto;
        margin-right: auto;
    }
}

.description {
    @include spacing('margin-bottom', 's-32');

    max-width: rem(360px);

    @include mq(null, xl) {
        max-width: get-vw(360px);
    }

    @include mq(sm-md) {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0;
    }
}

.shape {
    position: absolute;
    width: calc(100vw - #{get-vw(120px)});
    height: 100%;
    background: $beige;
    z-index: getZindex(negative);
    border-radius: 300px;
    top: 0;
    left: get-vw(-120px);

    @include mq(null, xl) {
        border-radius: get-vw(300px);
    }

    @include mq(lg) {
        left: get-vw(-60px);
    }

    @include mq(md) {
        width: 100vw;
        left: get-vw(-120px);
    }

    @include mq(sm-md) {
        display: none;
    }

    &.lightBeige {
        background-color: $smoke;
    }
}

.mobileShape {
    position: absolute;
    width: calc(100vw - #{get-vw(120px)});
    height: 0;
    padding-bottom: percentage(186, 328);
    background: $beige;
    z-index: getZindex(negative);
    border-radius: 300px;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
    display: none;

    @include mq(sm-md) {
        display: block;
    }

    &.lightBeige {
        background-color: $smoke;
    }
}

.imageWrapper {
    width: 48%;
    position: relative;
    z-index: getZindex(default);
    transform: translateY(-15%);

    @include mq(sm-md) {
        @include spacing('margin-top', 's-16');
        @include spacing('margin-bottom', 's-56');

        transform: none;
        width: 100%;
    }
}

.image {
    width: 100%;
    height: 0;
    padding-bottom: percentage(581, 512);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    @include mq(sm-md) {
        width: 80%;
        padding-bottom: 80%;
        margin: 0 auto;
    }
}

.link {
    @include mq(sm-md) {
        display: none;
    }
}

.mobileLink {
    @include spacing('margin-top', 's-24');

    display: none;

    @include mq(sm-md) {
        display: block;
    }
}
