@use 'sass:math';

@import 'styles/scoped';

.horizontalOvalImageSlider {
    @include spacing('padding-top', 's-120');
    @include spacing('padding-bottom', 's-120');

    @include mq(sm-md) {
        @include spacing('padding-top', 's-80');
        @include spacing('padding-bottom', 's-80');
    }
}

.contentWrapper {
    position: relative;

    @include spacing('margin-bottom', 's-80');
}

.leftCol {
    display: block;
    width: 100%;
    max-width: rem(300px);
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    z-index: getZindex(default);

    @include mq(null, xl) {
        max-width: get-vw(300px);
    }
    @include mq(md) {
        @include spacing('margin-bottom', 's-64');
        text-align: center;
        position: static;
        transform: none;
        margin-left: auto;
        margin-right: auto;
    }
}

.rightCol {
    display: block;
    position: relative;
    transform: translateX(get-vw(60px));

    @include mq(md) {
        transform: none;
    }

    :global {
        .swiper-container {
            overflow: visible;
        }
    }
}

.eyebrow,
.title,
.description {
    @include mq(md) {
        margin-left: auto;
        margin-right: auto;
    }
}

.eyebrow {
    @include spacing('margin-bottom', 's-32');

    display: block;
    color: $red;
}

.title {
    @include spacing('margin-bottom', 's-16');

    display: block;
}

.description {
    display: block;
}

.btn {
    @include spacing('margin-top', 's-32');

    display: block;
}

.scrollbar {
    width: 100%;
    display: block;
}

.scrollbarItem {
    width: 100%;
    height: 3px;
    display: block;
    position: relative;
    background-color: $white;
    overflow: hidden;
    text-indent: -9999px;
    z-index: getZindex(shapes);

    @include mq(null, xl) {
        height: get-vw(3px);
    }

    & > div {
        display: block;
        top: 0;
        left: 0;
        height: 100%;
        border-radius: 40px;
        position: absolute;
        background-color: $red;
        cursor: move;

        @include mq(null, xl) {
            border-radius: get-vw(40px);
        }
    }
}

.scrollbarItemInverted {
    background-color: $beige;
}

.slideItemParent {
    :global {
        .swiper-wrapper {
            align-items: center;
        }
    }
}

.slideItemWrapper {
    width: rem(300px);
    transform: scale(0.8);
    transition: getTransition(slide);
    cursor: grab;

    @include mq(null, xl) {
        width: get-vw(300px);
    }

    @include mq(md) {
        width: 40vw;
    }

    @include mq(sm-md) {
        width: 100%;
    }

    &:global {
        &.swiper-slide-active {
            transform: scale(1);
            padding: 0 get-vw(30px);

            @include mq(sm-md) {
                padding: 0;
            }

            :local {
                .slideName,
                .slideSport {
                    opacity: 1;
                }
            }
        }
    }
}

.slideItem {
    display: block;
    text-align: center;
}

.slidePicFigure {
    @include spacing('margin-bottom', 's-24');

    display: block;
    overflow: hidden;
    position: relative;
    border-radius: 320px;
    height: 0;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);

    @include mq(null, xl) {
        border-radius: get-vw(320px);
    }

    img {
        display: none;
    }

    &Desktop {
        display: block;
        padding-bottom: percentage(528, 300);

        @include mq(sm-md) {
            display: none;
        }
    }

    &Mobile {
        display: none;
        padding-bottom: percentage(186, 328);

        @include mq(sm-md) {
            display: block;
        }
    }
}

.slidePic {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 110%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    display: block;
}

.slideName,
.slideSport {
    opacity: 0;
    transition: getTransition();
}

.slideName {
    display: block;
}

.slideSport {
    @include spacing('margin-top', 's-4');
    display: block;
    color: $faded;
}
