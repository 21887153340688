@import 'styles/scoped';

.box {
    display: block;
}

.figure {
    width: 100%;
    display: block;

    img {
        width: 100%;
        display: block;
    }
}

.quote {
    display: block;
    background-color: $beige;
    border-radius: 0 0 6px 6px;

    @include spacing('padding', 's-56');

    @include mq(null, xl) {
        border-radius: 0 0 get-vw(6px) get-vw(6px);
    }

    @include mq(md) {
        @include spacing('padding-top', 's-64');
        @include spacing('padding-bottom', 's-40');
        @include spacing('padding-left', 's-40');
        @include spacing('padding-right', 's-40');
    }
}

.text {
    display: block;
    position: relative;
}

.textQuoteSign {
    @include spacing('top', 's-4');

    right: 100%;
    margin-right: 3%;
    position: absolute;

    path {
        fill: $red;
    }

    @include mq(md) {
        top: rem(-30px);
        margin-right: 0;
    }
}

.name {
    display: block;
    font-weight: 500;

    @include spacing('margin-top', 's-32');
}
