@import 'styles/scoped';

.wave {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    z-index: getZindex(shapes);
    transform: rotate(180deg);
}

.svg {
    position: relative;
    display: block;
    width: 300%;
    height: 125px;
    transform: rotateY(180deg);

    @include mq(lg) {
        height: 90px;
    }

    @include mq(sm-md) {
        height: 40px;
    }
}

.path {
    fill: $white;

    &.beige {
        fill: $beige;
    }
}
