@use 'sass:math';

@import 'styles/scoped';

.main {
    width: 100%;
    display: block;
}

.figure {
    display: block;
    overflow: hidden;
    border-radius: 10px;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);

    @include mq(null, xl) {
        border-radius: get-vw(10px);
    }

    img {
        width: 100%;
        display: block;
    }
}

.iframe {
    $iframe-width: 560;
    $iframe-height: 315;

    height: 0;
    overflow: hidden;
    padding-top: 35px;
    position: relative;
    padding-bottom: percentage($iframe-height, $iframe-width);

    iframe {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
    }
}
