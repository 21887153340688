@import 'styles/scoped';
$form-input-placeholder-color: rgba($black, 0.6);

.dropdown {
    :global {
        .react-dropdown-select {
            width: 100%;
            height: auto;
            padding: 0;
            margin: 0;
            min-height: 0;
            border-radius: 6px;
            background-color: $white;
            border: 1px solid $beige;
            transition: getTransition();

            @include mq(null, xl) {
                border-radius: get-vw(6px);
                border: get-vw(1px) solid $beige;
            }

            @include hover {
                border: 1px solid $beige;

                @include mq(null, xl) {
                    border: get-vw(1px) solid $beige;
                }
            }

            &.has-error {
                border: 1px solid $red;

                @include mq(null, xl) {
                    border: get-vw(1px) solid $red;
                }
            }

            &:focus,
            &:focus-within {
                outline: none;
                box-shadow: none;
                border: 1px solid $beige;

                @include mq(null, xl) {
                    border: get-vw(1px) solid $beige;
                }
            }
        }

        .react-dropdown-select-type-single,
        .react-dropdown-select-type-multi {
            @include spacing('padding-left', 's-24');
            @include spacing('padding-right', 's-24');
            @include spacing('height', 's-64');

            width: 100%;
            padding-top: 0;
            padding-bottom: 0;
            flex-wrap: nowrap;
            align-content: center;
            align-items: center;

            @include mq(md) {
                @include spacing('height', 's-80');
            }

            & > span {
                @include spacing('padding-top', 's-4');
                @include spacing('padding-bottom', 's-4');
                @include spacing('padding-right', 's-12');

                padding-left: 0;
                width: 100%;
                display: block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .react-dropdown-select-input {
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            position: absolute;
        }

        .react-dropdown-select-dropdown {
            z-index: 10;
            top: -1px;
            right: -1px;
            left: -1px;
            bottom: auto;
            box-shadow: none;
            border-radius: 6px;
            background-color: $white;
            border: 1px solid $beige;
            min-height: calc(100% + 2px);
            width: auto;
            max-height: 1000px;
            cursor: default;

            @include mq(null, xl) {
                border-radius: get-vw(6px);
                border: get-vw(1px) solid $beige;
            }
        }

        .react-dropdown-select-item-selected {
            color: $black;
        }

        .react-dropdown-select-item {
            @include spacing('min-height', 's-64');
            @include spacing('padding-left', 's-24');
            @include spacing('padding-right', 's-24');

            display: flex;
            cursor: pointer;
            position: relative;
            align-items: center;
            align-content: center;
            background-color: $white;
            justify-content: flex-start;
            border-top: 1px solid $beige;
            transition: getTransition();

            @include mq(md) {
                @include spacing('height', 's-80');
            }

            @include hover {
                color: $red;
                background-color: $white;
            }

            &:first-of-type {
                border-top: none;
            }
        }

        .react-dropdown-select-item-active {
            color: $red;
            pointer-events: none;
        }

        .react-dropdown-select-item-multi {
            @include spacing('margin-bottom', 's-8');

            min-height: 0;
            border-top: none;
            align-items: flex-start;
            align-content: flex-start;

            @include mq(md) {
                height: auto;
            }
        }

        .react-dropdown-select-item-hidden {
            display: none !important;
        }

        .react-dropdown-select-item-checkbox {
            @include spacing('margin-right', 's-8');

            top: 0.2em;
            display: block;
            position: relative;

            @include mq(md) {
                top: 0.1em;
            }

            &:before,
            &:after {
                @include spacing('width', 's-16');
                @include spacing('height', 's-16');

                line-height: 1;
                content: '';
                transition: getTransition();
                top: 1px;
                left: 0;
                display: block;

                @include mq(lg) {
                    width: rem(16px);
                    height: rem(16px);
                }
            }

            &:before {
                position: relative;
                background-color: $white;
                border-radius: 3px;
                border: 1px solid $maize;

                @include mq(null, xl) {
                    border-radius: get-vw(3px);
                    border: get-vw(1px) solid $maize;
                }
            }

            &:after {
                position: absolute;
                transform: scale(0);
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                background-color: $white;
                background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTkuNyAxLjU0M0wzLjc0MiA3LjUuMiAzLjk1NmwxLjA1Ny0xLjA0NEwzLjc0MyA1LjRsNC45LTQuOSAxLjA1NiAxLjA0M3oiIGZpbGw9IiNDRDAwMzkiLz48L3N2Zz4=');
            }

            &.is-checked:after {
                transform: scale(0.7);
            }
        }

        .react-dropdown-custom-search {
            @include spacing('padding-right', 's-32');
            @include spacing('padding-left', 's-24');
            @include spacing('height', 's-64');

            width: 100%;
            display: flex;
            border-radius: 0;
            flex-wrap: nowrap;
            flex-direction: row;
            justify-content: flex-start;
            align-content: center;
            align-items: center;
            background-color: transparent;
            -webkit-appearance: none;
            border: none;
            margin: 0;
            padding-top: 0;
            padding-bottom: 0;
            color: $black;

            @include mq(md) {
                @include spacing('height', 's-80');
            }

            &:focus {
                outline: none;
            }

            &::placeholder {
                color: $form-input-placeholder-color;
                opacity: 1;
            }
        }

        .react-dropdown-select-scrollbox {
            display: block;
            max-height: rem(245px);
            overflow: auto;
            border-top: 1px solid $beige;

            @include mq(null, xl) {
                max-height: get-vw(245px);
            }
        }

        .react-dropdown-select-scrollbox-multi {
            @include spacing('padding-top', 's-16');
            @include spacing('padding-bottom', 's-16');
        }

        .react-dropdown-select-dropdown-handle {
            @include spacing('right', 's-24');

            top: 0;
            bottom: 0;
            display: flex;
            z-index: 11;
            position: absolute;
            align-items: center;
            align-content: center;
            justify-content: flex-start;
        }

        .react-dropdown-select-arrow {
            @include spacing('width', 's-8');
            @include spacing('height', 's-8');

            display: block;
            transition: getTransition();

            &.is-active {
                transform: scaleY(-1);
            }

            path {
                fill: $red;
            }
        }
    }

    &--smaller {
        display: inline-flex;

        :global {
            .react-dropdown-select {
                width: auto;
                border: none;
                background-color: transparent;

                @include hover {
                    border: none;
                }

                &:focus,
                &:focus-within {
                    border: none;
                }
            }

            .react-dropdown-select-type-single {
                height: auto;
                padding: 0;
            }

            .react-dropdown-select-dropdown-handle {
                right: -2px;
            }

            .react-dropdown-select-dropdown {
                top: 100%;
            }
        }
    }

    &--disabled {
        opacity: 0.5;
        cursor: default;
        pointer-events: none;

        * {
            pointer-events: none !important;
        }
    }

    &--filled {
        :global {
            .react-dropdown-select {
                background-color: $beige;
            }
        }
    }
}

.placeholder {
    color: $form-input-placeholder-color;
    opacity: 1;
}
