@import 'styles/scoped';

.excerpt {
    @include spacing('padding-bottom', 's-32');

    display: block;
    width: 100%;
    max-width: rem(600px);

    @include mq(null, xl) {
        max-width: get-vw(600px);
    }

    @include mq(md) {
        max-width: 100%;
    }
}

.cols {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: flex-start;

    @include mq(md) {
        display: block;
    }
}

.leftCol {
    flex: 1 1 percentage(13, 20);

    @include mq(md) {
        flex: 1 1 100%;
    }
}

.rightCol {
    flex: 0 0 percentage(7, 20);
    padding-left: percentage(1, 20);

    @include mq(md) {
        @include spacing('padding-top', 's-32');

        flex: 1 1 100%;
        padding-left: 0;
    }
}

.box {
    @include spacing('padding-top', 's-64');
    @include spacing('padding-bottom', 's-64');
    @include spacing('padding-left', 's-56');
    @include spacing('padding-right', 's-56');

    display: block;
    width: 100%;
    background-color: $beige;
    border-radius: 6px;
    overflow: hidden;
    position: relative;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);

    @include mq(null, xl) {
        border-radius: get-vw(6px);
    }

    @include mq(md) {
        @include spacing('padding', 's-40');
    }
}

.boxLink {
    display: block;
    position: absolute;
    z-index: 5;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    cursor: pointer;
}

.boxTitle {
    display: block;
}

.space {
    @include spacing('margin-bottom', 's-40');
}

.fakeLink {
    @include spacing('margin-top', 's-8');
}
