@use 'sass:math';

@import 'styles/scoped';

.featuredTopicCta {
    @include spacing('padding-top', 's-200');
    @include spacing('padding-bottom', 's-200');

    color: $white;
    text-align: center;
    position: relative;
    z-index: getZindex(parallax, 1);

    @include mq(sm-md) {
        @include spacing('padding-top', 's-160');
        @include spacing('padding-bottom', 's-80');
    }
}

.eyebrowImage {
    display: block;
    margin-left: auto;
    margin-right: auto;
    @include spacing('padding-bottom', 's-56');
    max-width: get-vw(100px);
}

.eyebrow {
    @include spacing('margin-bottom', 's-32');
}

.title {
    @include spacing('margin-bottom', 's-80');

    max-width: rem(840px);
    margin-left: auto;
    margin-right: auto;

    @include mq(null, xl) {
        max-width: get-vw(840px);
    }

    &.hasDescription {
        @include spacing('margin-bottom', 's-32');

        max-width: rem(960px);

        @include mq(null, xl) {
            max-width: get-vw(960px);
        }
    }

    @include mq(md) {
        padding-bottom: 160px;
    }
}

.description {
    @include spacing('margin-bottom', 's-56');

    max-width: rem(660px);
    margin-left: auto;
    margin-right: auto;

    @include mq(null, xl) {
        max-width: get-vw(660px);
    }

    @include mq(md) {
        display: none;
    }
}

.sectionBackgrounds {
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    top: 0;
    left: 0;
    z-index: getZindex(parallax);
    pointer-events: none;
}

.sectionBackground {
    padding-bottom: percentage(738, 1440);
    position: absolute;
    display: block;
    width: rem(299px);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    @include mq(null, xl) {
        width: get-vw(299px);
    }

    @include mq(lg-xl) {
        max-width: rem(250px);
    }

    @include mq(md) {
        display: none;
    }

    .backgroundsMobile & {
        background-size: contain;

        &:first-child {
            background-position: left bottom;
        }

        &:last-child {
            background-position: right top;
        }

        @include mq(md) {
            display: block;
            width: 15%;
        }
    }

    &:first-child {
        left: 0;
        top: 15%;
    }

    &:last-child {
        right: 0;
        top: 0;
    }

    &.alignBottom {
        bottom: -5%;
        top: unset;
    }
}
