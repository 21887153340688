@import 'styles/scoped';

.arrowButton {
    width: rem(40px);
    height: rem(40px);
    display: inline-block;
    background: transparent;
    text-decoration: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
    outline: 0;
    border: 2px solid $red;
    vertical-align: top;
    -webkit-appearance: none;
    -moz-appearance: none;
    border-radius: 100%;
    transition: getTransition();
    color: $red;
    position: relative;
    overflow: hidden;

    @include mq(null, xl) {
        width: get-vw(40px);
        height: get-vw(40px);
        border: get-vw(2px) solid $red;
    }

    @include hover {
        &:not(:disabled) {
            border-color: $black;
            color: $black;

            .iconWrapper {
                &:first-child {
                    transform: translateX(-100%);
                }

                &:last-child {
                    transform: translateX(0%);
                    opacity: 1;
                }
            }

            &.right {
                .iconWrapper {
                    &:first-child {
                        transform: translateX(100%);
                    }
                }
            }
        }
    }

    &:disabled {
        opacity: 0.5;
    }
}

.iconWrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    background: transparent;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: getCustomTransition(transform, opacity);

    &:last-child {
        transform: translateX(100%);
        opacity: 0;

        .right & {
            transform: translateX(-100%);
        }
    }
}

.icon {
    font-size: get-vw(10px);
    width: auto;

    @include mq(lg) {
        font-size: rem(10px);
    }
}
