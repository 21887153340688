@import 'styles/scoped';

.main {
    width: 100%;
    display: block;
    background-color: $white;
}

.title {
    display: block;

    @include spacing('margin-bottom', 's-32');
}

.container {
    display: flex;

    @include mq(lg) {
        flex-direction: column;
    }
}

.contentWrapper {
    width: percentage(9, 20);

    @include mq(lg) {
        @include spacing('margin-bottom', 's-64');

        width: 100%;
    }
}

.content {
    max-width: get-vw(420px);

    @include mq(lg) {
        max-width: rem(420px);
    }
}

.formWrapper {
    width: percentage(11, 20);

    @include mq(lg) {
        width: 100%;
    }
}

.form,
.formStatus {
    @include spacing('padding-top', 's-64');
    @include spacing('padding-bottom', 's-64');

    background-color: $beige;
    border-radius: 6px;
    padding-left: percentage(1, 11);
    padding-right: percentage(1, 11);

    @include mq(null, xl) {
        border-radius: get-vw(6px);
    }

    @include mq(lg) {
        padding-left: percentage(1, 20);
        padding-right: percentage(1, 20);
    }
}

.formStatus {
    position: relative;
}

.formReturnBtn {
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    cursor: default;
    position: absolute;
}

.formStatusTitle {
    @include spacing('margin-bottom', 's-32');
}

.formStatusMessage {
    border: 0;
    margin: 0;
    padding: 0;
    -webkit-appearance: none;
    background-color: transparent;

    a {
        text-decoration: underline;
    }
}
