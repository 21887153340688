@use 'sass:math';

@import 'styles/scoped';

.btn {
    @include mq(lg) {
        @include spacing('padding-left', 's-40');
        @include spacing('padding-right', 's-40');
    }

    @include mq(md) {
        @include spacing('padding-left', 's-32');
        @include spacing('padding-right', 's-32');
    }
}

.overlay {
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    z-index: getZindex(modal);
    position: fixed;
    background-color: $white;
    opacity: 0;
    visibility: hidden;
    transition: getTransition();

    &Opened {
        opacity: 1;
        visibility: visible;
    }

    &Close {
        opacity: 0;
        visibility: hidden;
    }
}

.main {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    min-height: 100vh;
    align-items: center;
    align-content: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-25px);
    transition: getTransition();

    &Opened {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    }

    &Close {
        opacity: 0;
        visibility: hidden;
        transform: translateY(-25px);
    }
}

.container {
    @include spacing('padding-top', 's-32');
    @include spacing('padding-bottom', 's-32');

    width: 100%;
    display: block;
    padding-left: percentage(1, 24);
    padding-right: percentage(1, 24);
}

.header {
    width: 100%;
    display: flex;
    padding: 5px 0;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: space-between;
}

.title {
    width: 100%;
    padding: 5px 0;
    display: block;
    color: $red;
    white-space: nowrap;
    overflow: hidden;
    text-transform: uppercase;
    text-overflow: ellipsis;
}

.btnClose {
    color: $faded;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-end;
    align-content: center;
    align-items: center;
    -webkit-appearance: none;
    margin: 0;
    padding: 0;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
    background-color: transparent;
    white-space: nowrap;
}

.btnIcon {
    width: 15px;
    height: 15px;
    margin-left: 8px;
}

.video {
    width: 100%;
    display: block;
}
