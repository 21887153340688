@use 'sass:math';

@import 'styles/scoped';

.mediaHeader {
    height: 0;
    pointer-events: none;
    padding-bottom: percentage(810, 1440);

    @include mq(lg) {
        padding-bottom: 0;
        height: 100vh;
    }
}

.content {
    @include spacing('padding-top', 's-120');
    @include spacing('padding-bottom', 's-160');

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 percentage(1, 24);
    text-align: center;
    color: $white;

    @include mq(lg-xl) {
        @include spacing('padding-top', 's-80');
        @include spacing('padding-bottom', 's-120');

        justify-content: center;
    }

    @include mq(md) {
        @include spacing('padding-top', 's-80');
        @include spacing('padding-bottom', 's-80');
    }

    &::before {
        content: '\A';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba($black, 0.6);
        z-index: getZindex(negative);
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
    }
}

.breadcrumbs {
    @include spacing('margin-bottom', 's-40');
}

.breadcrumb {
    &::after {
        @include spacing('margin-left', 's-8');
        @include spacing('margin-right', 's-8');

        pointer-events: none;
        content: '>';
        display: inline-block;
    }
}

.breadcrumbActive {
    color: $red;
}

.eyebrow {
    pointer-events: all;
    @include spacing('margin-bottom', 's-32');

    max-width: rem(240px);

    @include mq(null, xl) {
        max-width: get-vw(240px);
    }
}

.title {
    pointer-events: all;
    @include spacing('margin-bottom', 's-16');

    max-width: rem(840px);

    @include mq(null, xl) {
        max-width: get-vw(840px);
    }
}

.description {
    pointer-events: all;
    max-width: rem(420px);

    @include mq(null, xl) {
        max-width: get-vw(420px);
    }
}

.button {
    pointer-events: all;
    @include spacing('padding-top', 's-40');
}

.buttonIcon {
    @include spacing('margin-left', 's-8');

    font-size: rem(9px);

    @include mq(null, xl) {
        font-size: get-vw(9px);
    }
}

.imageSlider,
.video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    z-index: getZindex(negative);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
}

.imageSliderSlide {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.sliderImage {
    width: 100%;
    height: 110%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    top: -10%;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
}
