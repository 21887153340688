@use 'sass:math';

@import 'styles/scoped';

.section {
    @include spacing('padding-bottom', 's-120');

    width: 100%;
    display: block;
    position: relative;
    background-color: $beige;

    @include mq(sm-md) {
        @include spacing('padding-bottom', 's-80');
    }

    &beige {
        background-color: $beige;
    }

    &white {
        background-color: $white;
    }
}

.sectionInner {
    display: block;
    position: relative;
}

.sectionTitleWrapper {
    @include spacing('padding-top', 's-64');
    @include spacing('padding-bottom', 's-64');

    z-index: getZindex(default, 1);
    display: block;
    position: relative;
    padding: 0 percentage(1, 24);

    @include mq(md) {
        @include spacing('padding-top', 's-80');

        padding-bottom: 0;
    }

    &beige {
        background-color: $beige;
    }

    &white {
        background-color: $white;
    }
}

.sectionTitle {
    display: block;
    width: 100%;
    max-width: rem(600px);
    text-align: center;
    margin: 0 auto;

    @include mq(null, xl) {
        max-width: get-vw(600px);
    }

    @include mq(md) {
        max-width: 100%;
    }
}

.topwave {
    z-index: getZindex(default);
    display: block;
    position: relative;
    width: 100%;
    height: rem(240px);
    background-color: $beige;

    @include mq(md) {
        height: rem(180px);
    }

    &beige {
        background-color: $beige;
    }

    &white {
        background-color: $white;
    }
}

.topwaveshape {
    display: block;
    width: 100%;
    height: 0;
    top: 100%;
    left: 0;
    position: absolute;
}

.row {
    width: 100%;
    z-index: getZindex(default, 1);
    margin-top: rem(-170px);
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-content: flex-start;
    align-items: flex-start;

    &--small {
        padding: 0 percentage(1, 24);

        @include mq(lg) {
            padding: 0 percentage(2, 24);
        }

        @include mq(md) {
            padding: 0 percentage(1, 24);
            margin-top: rem(-120px);
        }
    }

    &--big {
        padding: 0 percentage(2, 24);

        @include mq(md) {
            padding: 0 percentage(1, 24);
            margin-top: rem(-120px);
        }
    }
}

.column {
    display: block;

    &--small {
        width: 33.3%;
        margin-bottom: rem(50px);
        padding-left: percentage(0.5, 24);
        padding-right: percentage(0.5, 24);

        @include mq(lg) {
            width: 50%;
            margin-bottom: rem(80px);
        }

        @include mq(md) {
            width: 100%;
            padding: 0;
            margin-top: 0 !important;
            margin-bottom: rem(60px) !important;
        }

        &:last-child {
            @include mq(md) {
                margin-bottom: 0 !important;
            }
        }

        &:nth-child(3n + 1) {
            margin-top: rem(88px);

            @include mq(lg) {
                margin-top: 0;
            }
        }

        &:nth-child(3n + 2) {
            margin-top: rem(36px);

            @include mq(lg) {
                margin-top: 0;
            }
        }

        &:nth-child(1) {
            margin-top: rem(88px);

            @include mq(lg) {
                margin-top: rem(40px);
            }
        }

        &:nth-child(2) {
            margin-top: rem(36px);

            @include mq(lg) {
                margin-top: 0;
            }
        }

        &:nth-child(2n + 1) {
            @include mq(lg) {
                margin-top: rem(40px);
            }
        }
    }

    &--big {
        width: 50%;
        margin-bottom: rem(80px);
        padding-left: percentage(1.5, 24);
        padding-right: percentage(1.5, 24);

        @include mq(md) {
            width: 100%;
            margin-top: 0 !important;
            margin-bottom: rem(60px) !important;
            padding: 0;
        }

        &:last-child {
            @include mq(md) {
                margin-bottom: 0 !important;
            }
        }

        &:nth-child(1),
        &:nth-child(2n + 1) {
            margin-top: rem(40px);
        }
    }
}

.item {
    text-align: center;
}

.figure {
    @include spacing('margin-bottom', 's-40');
    @include spacing('top', 's-40');

    display: block;
    margin-left: auto;
    margin-right: auto;
    position: relative;

    @include mq(md) {
        top: 0;
    }

    img {
        display: block;
        margin: 0 auto;
        width: 100%;
    }
}

.eyebrow {
    @include spacing('margin-bottom', 's-16');

    display: block;
    color: $red;
    font-weight: normal;
    text-transform: uppercase;
}

.title {
    @include spacing('margin-bottom', 's-8');

    display: block;
}

.text {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: rem(240px);
    color: rgba($black, 0.7);

    @include mq(null, xl) {
        max-width: get-vw(240px);
    }

    @include mq(md) {
        max-width: rem(320px);
    }
}

.link {
    @include spacing('margin-top', 's-16');

    display: block;

    @include mq(md) {
        @include spacing('margin-bottom', 's-16');
    }
}

.btn {
    @include spacing('margin-top', 's-56');

    display: block;
    margin-left: auto;
    text-align: center;
    margin-right: auto;
    padding-left: percentage(1, 24);
    padding-right: percentage(1, 24);

    @include mq(md) {
        @include spacing('padding-top', 's-56');
    }
}

.scrollbar {
    @include spacing('margin-top', 's-64');

    width: 100%;
    display: block;
}

.scrollbarItem {
    width: 100%;
    height: 3px;
    display: block;
    position: relative;
    background-color: $white;
    overflow: hidden;
    text-indent: -9999px;
    z-index: getZindex(shapes);
    margin-top: 2em;

    @include mq(null, xl) {
        height: get-vw(3px);
    }

    & > div {
        display: block;
        top: 0;
        left: 0;
        height: 100%;
        border-radius: 40px;
        position: absolute;
        background-color: $red;
        cursor: move;

        @include mq(null, xl) {
            border-radius: get-vw(40px);
        }
    }
}

.scrollbarItemInverted {
    background-color: $beige;
}
