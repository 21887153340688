@use 'sass:math';

@import 'styles/scoped';

.verticalDoubleImageFeaturedModule {
    @include spacing('padding-top', 's-120');
    @include spacing('padding-bottom', 's-120');

    display: flex;
    align-items: center;

    @include mq(sm-md) {
        @include spacing('padding-top', 's-80');
        @include spacing('padding-bottom', 's-80');

        flex-direction: column;
    }
}

.content {
    width: percentage(8, 18);
    padding-right: percentage(1, 18);
    display: flex;

    @include mq(sm-md) {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
        order: 2;
        text-align: center;
    }

    .contentRight & {
        order: 2;
        padding-right: 0;
        justify-content: flex-end;

        @include mq(lg) {
            padding-left: percentage(1, 18);
        }

        @include mq(sm-md) {
            padding-left: 0;
            justify-content: flex-start;
        }
    }
}

.contentInner {
    max-width: rem(420px);

    @include mq(null, xl) {
        max-width: get-vw(420px);
    }

    @include mq(sm-md) {
        max-width: 100%;
        margin: 0 auto;
    }
}

.eyebrow {
    @include spacing('margin-bottom', 's-32');

    color: $red;
}

.title {
    @include spacing('margin-bottom', 's-16');

    max-width: rem(310px);

    @include mq(null, xl) {
        max-width: get-vw(310px);
    }
}

.description {
    @include spacing('margin-bottom', 's-32');

    max-width: rem(300px);

    @include mq(null, xl) {
        max-width: get-vw(300px);
    }
}

.logo {
    @include spacing('margin-bottom', 's-40');
    @include spacing('width', 's-120');

    @include mq(sm-md) {
        @include spacing('width', 's-264');

        margin-left: auto;
        margin-right: auto;
    }
}

.images {
    width: percentage(10, 18);
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include mq(sm-md) {
        @include spacing('margin-bottom', 's-64');

        width: 100%;
        padding-left: 0;
        padding-right: 0;
        order: 1;
        flex-direction: column;
    }

    .contentRight & {
        order: 1;
    }
}

.bigImage,
.smallImage {
    height: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 320px;
    overflow: hidden;
    position: relative;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);

    @include mq(null, xl) {
        border-radius: get-vw(320px);
    }

    &.hasBorderRadius {
        border-radius: 20px;

        @include mq(null, xl) {
            border-radius: get-vw(20px);
        }
    }
}

.bigImage {
    width: percentage(5, 10);
    padding-bottom: percentage(600, 600);

    @include mq(sm-md) {
        width: 100%;
        padding-bottom: percentage(186, 328);
    }

    &Desktop {
        display: block;

        @include mq(sm-md) {
            display: none;
        }
    }

    &Mobile {
        display: none;

        @include mq(sm-md) {
            display: block;
        }
    }
}

.smallImage {
    width: percentage(4, 10);
    padding-bottom: percentage(480, 600);

    @include mq(sm-md) {
        display: none;
    }
}

.imageBackground {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    height: 110%;
    top: -10%;
    left: 0;
    display: block;
}
