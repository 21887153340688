@use 'sass:math';

@import 'styles/scoped';

.main {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: fixed;
    overflow: auto;
    transition: getTransition();
    opacity: 0;
    visibility: hidden;
    z-index: getZindex(modal);
    padding: percentage(1, 24);
}

.inner {
    width: 100%;
    min-height: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: center;
    pointer-events: none;
}

.iframe {
    width: 100%;
    max-width: 500px;
    pointer-events: all;
    position: relative;
    z-index: 2;
}

.hubspot {
    width: 100%;
    max-width: 500px;
    pointer-events: all;
    position: relative;
    z-index: 2;
    background-color: $white;
    @include spacing('padding', 's-32');
}

.overlay {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    position: fixed;
    background-color: $black-40;
    margin: 0;
    padding: 0;
    border: none;
    border-radius: 0;
    pointer-events: all;
}

.isOpened {
    opacity: 1;
    visibility: visible;
}
