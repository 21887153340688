@use 'sass:math';
@import 'styles/scoped';

.container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: getZindex('modal');
    background-color: rgba($white, 0.7);
    padding: 2.5% percentage(1, 24);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    opacity: 0;

    @include mq(sm) {
        padding-top: 1%;
        padding-bottom: 1%;
    }

    &.isVideo {
        .modal {
            color: $white;
            position: relative;
            overflow: hidden;
            border-radius: 60px;
            height: 100%;

            &.isVideoPlaying {
                .btnClose {
                    // todo: make it more visible regardless of video background
                    text-shadow: 1px 1px $black-80;

                    &:hover {
                        opacity: 1;
                    }
                }

                .videoOverlay {
                    background-color: transparent;
                    z-index: 0;
                }

                .videoPlay,
                .share,
                .videoText {
                    opacity: 0;
                }
            }
        }

        .header {
            color: $white;
            background-color: transparent;
            position: absolute;
            top: 0;
            right: 0;
            z-index: getZindex('modal');
        }

        .btnClose {
            color: $white;
            opacity: 0.75;
            position: absolute;
            top: get-vw(36px);
            right: get-vw(60px);
        }

        .videoContainer {
            width: 100%;
            height: 100%;
            color: $white;
        }

        .videoOverlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: $black-50;
            z-index: 1;
            transition: background-color 0.1s ease-in;

            .videoPlay,
            .share,
            .videoText {
                opacity: 1;
                transition: opacity 0.2s ease-in;
            }
        }

        .videoPlay {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            cursor: pointer;
        }

        .share {
            position: absolute;
            bottom: get-vw(45px);
            right: get-vw(60px);

            svg {
                opacity: 0.5;
                fill: white;

                &:hover {
                    opacity: 1;
                }
            }
        }

        .videoText {
            position: absolute;
            top: get-vw(60px);
            left: get-vw(60px);

            @include mq(sm-md) {
                left: get-vw(75px);
            }

            .eyebrow {
                @include spacing('margin-bottom', 's-8');
            }
        }

        .video {
            width: 100%;
            height: 100%;
            display: block;
        }
    }
}

.modal {
    flex: 1;
    position: relative;
    background-color: $white;
    box-shadow: 0 get-vw(4px) get-vw(20px) rgba($black, 0.1);
    overflow: auto;
    max-height: calc(100% - 3%);
}

.header {
    display: flex;
    justify-content: flex-end;
    background-color: $beige;
}

.content {
    gap: percentage(2, 18);
    padding-left: percentage(2, 22);
    padding-right: percentage(2, 22);
    padding-bottom: get-vh(40px);
    display: grid;
    align-items: center;
    grid-template-columns: percentage(1, 18) percentage(4, 18) percentage(11, 18);
    position: relative;
    z-index: getZindex('modal');

    > div {
        z-index: getZindex('modal');
    }

    @include mq(sm-md) {
        @include spacing('gap', 's-40');
        grid-template-columns: 100%;
    }
}

.share {
    @include mq(md) {
        display: none;
    }
}

.btnClose {
    @include spacing('padding', 's-24');
    @include spacing('gap', 's-8');

    color: $black;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-end;
    align-content: center;
    align-items: center;
    -webkit-appearance: none;
    margin: 0;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
    background-color: transparent;
    white-space: nowrap;
    opacity: 0.5;

    &:hover {
        opacity: 1;
    }

    :global {
        .u-icon {
            transform: rotate(0deg);
            will-change: transform;
            transition: getTransition();
            vertical-align: bottom;
        }
    }

    &:hover :global {
        .u-icon {
            transform: rotate(-90deg);
        }
    }
}

.btnIcon {
    width: get-vw(15px);
    height: get-vw(15px);

    @include mq(md) {
        width: 15px;
        height: 15px;
    }
}

.topWave {
    z-index: 0;
}

.eyebrow {
    @include spacing('margin-bottom', 's-32');

    color: $red;
}

.title {
    @include spacing('margin-bottom', 's-8');
}

.country {
    @include spacing('margin-bottom', 's-48');
}

.quote {
    @include spacing('margin-bottom', 's-40');
    @include spacing('gap', 's-32');

    display: grid;
    grid-template-columns: get-vw(26px) 1fr;
}

.quoteIcon {
    width: get-vw(26px);
    color: $red;

    @include mq(sm) {
        width: get-vw(48px);
    }
}

.product {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;

    &Figure {
        flex-shrink: 0;
        flex-grow: 0;
        flex-basis: get-vw(180px);
        @include spacing('padding-right', 's-16');

        @include mq(md) {
            flex-basis: 120px;
        }
    }
}

.productEyebrow {
    @include spacing('margin-bottom', 's-8');

    opacity: 0.5;
}

.productName {
    @include spacing('margin-bottom', 's-16');
}
