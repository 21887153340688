@import 'styles/scoped';

.header {
    @include spacing('padding-top', 's-120');
    @include spacing('padding-bottom', 's-120');

    width: 100%;
    display: block;
    background: $beige;

    @include mq(sm-md) {
        @include spacing('padding-top', 's-264');
        @include spacing('padding-bottom', 's-80');
    }
}

.headerWrapper {
    display: flex;
    align-items: center;

    @include mq(sm-md) {
        display: block;
    }
}

.headerPhoto {
    display: block;
    width: percentage(11, 18);
    padding-right: percentage(3, 18);
    position: relative;

    @include mq(sm-md) {
        @include spacing('margin-bottom', 's-40');

        width: 100%;
        padding-right: 0;
    }
}

.headerPhotoDesktop {
    display: block;

    @include mq(sm-md) {
        display: none;
    }
}

.headerPhotoMobile {
    display: none;

    @include mq(sm-md) {
        display: block;
    }
}

.video {
    top: 0;
    left: 0;
    right: percentage(5.5, 18);
    bottom: 0;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;
    position: absolute;
    z-index: 10;

    @include mq(sm-md) {
        right: 0;
    }
}

.headerContent {
    width: percentage(7, 13);

    @include mq(sm-md) {
        width: 100%;
        text-align: center;
    }
}

.breadcrumbs {
    @include spacing('margin-bottom', 's-40');
}

.headerTitle {
    @include spacing('margin-bottom', 's-24');
}

.headerSubtitle {
    display: block;
    text-transform: uppercase;
    color: $faded;
    font-weight: normal;
    max-width: get-vw(250px);
    @include spacing('margin-bottom', 's-24');

    @include mq(lg) {
        max-width: rem(250px);
    }
}

.headerText {
    @include spacing('margin-top', 's-24');
}

.headerBtn {
    @include spacing('margin-top', 's-40');
}

.section {
    width: 100%;
    display: block;
    padding-left: percentage(3, 24);
    padding-right: percentage(3, 24);

    @include spacing('padding-bottom', 's-120');

    @include mq(lg) {
        padding-left: percentage(2, 24);
        padding-right: percentage(2, 24);
    }

    @include mq(md) {
        @include spacing('padding-bottom', 's-80');
    }
}

.sectionInner {
    width: 100%;
    display: block;
    position: relative;

    @include spacing('padding-top', 's-120');

    @include mq(md) {
        @include spacing('padding-top', 's-80');
    }
}

.share {
    width: rem(60px);
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: block;
    position: absolute;
    text-align: center;

    @include mq(md) {
        display: none;
    }

    @include mq(null, xl) {
        width: get-vw(60px);
    }
}

.shareInner {
    width: 100%;
    display: block;

    @include spacing('padding-top', 's-120');

    @include mq(md) {
        @include spacing('padding-top', 's-64');
    }
}

.article {
    display: block;
    position: relative;
    z-index: 1;
}

.articleBlock {
    width: 100%;
    display: block;
    padding-left: percentage(4, 18);
    padding-right: percentage(4, 18);

    @include spacing('margin-bottom', 's-64');

    @include mq(lg) {
        padding-left: percentage(3, 24);
        padding-right: percentage(2, 24);
    }

    @include mq(md) {
        padding-left: 0;
        padding-right: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.articleBlockFull {
    padding-left: 0;
    padding-right: 0;
}
